import { FC, ChangeEvent, useState, useEffect } from 'react';
import { Autocomplete } from '@mui/material';
import { Input } from '@components/Input/Input';

interface IOption {
    value: string;
    text: string;
}

interface IAutocompleteTextFieldProps {
    name: string;
    id: string;
    options: IOption[] | (() => IOption[]);
    setFieldValue: (key: string, value: string) => void;
    onChangeEmployerCreation: (value: string) => void;
    onChangeSearchCompany: (value: string) => void;
    label?: string;
    className?: string;
    placeholder?: string;
    error?: string;
    value?: string;
    disabled?: boolean;
    touched?: boolean;
    defaultOption?: IOption;
}

export const AutocompleteTextField: FC<IAutocompleteTextFieldProps> = ({
    name,
    options,
    setFieldValue,
    onChangeEmployerCreation,
    onChangeSearchCompany,
    label,
    className,
    placeholder,
    error,
    disabled = false,
    touched = false,
    defaultOption = { text: '', value: '' },
}) => {
    const [isTouched, setIsTouched] = useState<boolean>(touched);

    const definedOptionsType = typeof options === 'function' ? options() || [] : options;

    const handleBlur = (event: ChangeEvent<any>) => {
        const filter = definedOptionsType.filter((option: IOption) => option.text === event.target.value);
        const [selectedValue] = filter || [];

        if (!selectedValue) {
            onChangeEmployerCreation(event.target.value);
        }
    };

    const handleChange = (value: string) => {
        const filter = definedOptionsType.filter((option: IOption) => option.text === value);
        const [selectedValue] = filter || [];

        if (selectedValue) {
            setFieldValue(name, selectedValue?.value || value);
            setFieldValue('companyId', selectedValue?.value || value);
            setFieldValue('companyName', selectedValue?.text);
            setIsTouched(true);
        }
    };

    useEffect(() => {
        if (defaultOption.value) {
            setFieldValue(name, defaultOption?.value);
            setFieldValue('companyName', defaultOption?.text);
            setIsTouched(true);
        }
    }, [defaultOption]);

    return (
        <Autocomplete
            freeSolo
            disableClearable
            value={defaultOption.text}
            onBlur={handleBlur}
            onChange={(_, value) => handleChange(value)}
            onInputChange={(_, newInputValue) => {
                onChangeSearchCompany(newInputValue);
            }}
            getOptionLabel={(option: any) => option || ''}
            options={definedOptionsType.map((option) => option.text)}
            disabled={disabled}
            renderInput={(props) => {
                const { InputLabelProps, ...params } = props;
                return (
                    <Input
                        {...params}
                        name={name}
                        label={label}
                        placeholder={placeholder}
                        error={error}
                        touched={isTouched}
                        className={className}
                        autocomplete
                    />
                );
            }}
            sx={{
                '&.MuiAutocomplete-root .MuiInputBase-root': {
                    padding: '0',
                    height: '100%',
                    borderRadius: '12px',
                },
                '&.MuiAutocomplete-root .MuiInputBase-root .MuiAutocomplete-input': {
                    padding: '0 18px',
                },
                '& .Mui-error': {
                    backgroundColor: '#FCEAE6',
                },
            }}
        />
    );
};
