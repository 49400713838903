import config from './config';

export const auth = {
    region: config.AWS_REGION,
    userPoolId: config.AWS_COGNITO_POOL_ID,
    userPoolWebClientId: config.AWS_COGNITO_CLIENT_ID,
    cookieStorage: {
        // - Cookie domain (only required if cookieStorage is provided)
        // domain: config.DOMAIN_NAME,
        // (optional) - Cookie path
        path: '/',
        // (optional) - Cookie expiration in days
        expires: 365,
        // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        sameSite: 'strict',
        // (optional) - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: process.env.NODE_ENV !== 'development',
    },
    authenticationFlowType: 'CUSTOM_AUTH',
};
