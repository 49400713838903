interface IInputProps {
    placeholder: string;
    disabled: boolean | undefined;
    error?: boolean;
}

const sizeLarge = (props: IInputProps) => ({
    '&': {
        borderRadius: '12px',
        backgroundColor: props.error ? '#FCEAE6' : 'var(--c-grey-light)',
    },
    '& .MuiSelect-select': {
        paddingRight: '50px !important',
        color: 'var(--c-primary-black)',
        fontSize: '18px',
        lineHeight: '48px',
        padding: '0px 16px',
        height: '48px',
    },
    '& .MuiSelect-icon': {
        display: props.disabled ? 'none' : 'block',
        top: '50%',
        right: '20px',
        transform: 'translateY(-50%)',
        transitionDuration: '200ms',
    },
    '& .MuiSelect-iconOpen': {
        transform: 'translateY(-50%) rotateX(180deg) rotateY(0deg)',
    },
    '& .MuiSelect-select .notranslate::after': {
        content: `"${props.placeholder}"`,
        fontSize: '18px',
        color: 'var(--c-grey-dark)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--c-grey-normal)',
    },
});

const sizeSmall = (props: IInputProps) => ({
    '&': {
        borderRadius: '8px',
        backgroundColor: 'var(--c-grey-light)',
    },
    '& .MuiSelect-select': {
        paddingRight: '50px !important',
        color: 'var(--c-primary-black)',
        fontSize: '18px',
        lineHeight: '40px',
        padding: '0px 16px',
        height: '40px',
    },
    '& .MuiSelect-icon': {
        display: props.disabled ? 'none' : 'block',
        top: '50%',
        right: '20px',
        transform: 'translateY(-50%)',
        transitionDuration: '200ms',
    },
    '& .MuiSelect-iconOpen': {
        transform: 'translateY(-50%) rotateX(180deg) rotateY(0deg)',
    },
    '& .MuiSelect-select .notranslate::after': {
        content: `"${props.placeholder}"`,
        fontSize: '18px',
        color: 'var(--c-grey-dark)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--c-grey-normal)',
    },
});

export const themeProvider = {
    small: sizeSmall,
    large: sizeLarge,
};
