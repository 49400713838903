import { Button, ButtonProps } from '@mui/material';
import { useFormContext } from '@pages/Onboarding/context/FormContext';
import styles from './FormNavigation.module.scss';
import { LoadingButton } from '@mui/lab';

type Props = {
    isFirstStep?: boolean;
    isLoading?: boolean;
} & ButtonProps;

export const FormNavigation = ({ isFirstStep = false, isLoading = false, ...rest }: Props) => {
    const { setStep } = useFormContext();

    return (
        <div className={styles.wrapper}>
            {!isFirstStep && (
                <Button
                    fullWidth
                    variant="outlined"
                    type="button"
                    className={styles.backButton}
                    onClick={() => setStep((prev) => prev - 1)}
                >
                    Previous
                </Button>
            )}
            <LoadingButton fullWidth variant="contained" type="submit" loading={isLoading} {...rest}>
                Next step
            </LoadingButton>
        </div>
    );
};
