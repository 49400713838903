import { makeAutoObservable } from 'mobx';
import OOConnect from '@services/OOConnect';
import { IExchangeGrantTokenIncomingData } from '@interfaces/IExchangeGrantTokenIncomingData';
import { ISyncServicesRequestIncomingData } from '@interfaces/ISyncServicesRequestIncomingData';

interface ICompaniesData {
    createdAt: string;
    id: string;
    logo: string | null;
    name: string;
    userId: string;
    website: string;
}

interface IScopesData {
    id: string;
    name: string;
}

interface IRequestedServices {
    id: string;
    name: string;
    link: string;
}

interface ISyncServicesRequested {
    category: string;
    requestedServices: IRequestedServices[];
}

interface IActiveAccounts {
    zohoAccountName: string;
    companyId: string;
    companyName: string;
}

class AddServiceStore {
    isServicePopupShown = false;
    isEmailServicePopupShown = false;
    isRequestServicePopupShown = false;
    isZohoServicePopupShown = false;
    isEmployerConfirmationShown = false;

    companies: ICompaniesData[] = null!;
    typedNewEmployer: string = '';
    syncTypes: string[] = null!;
    domains: string[] = null!;
    environments: string[] = null!;
    scopes: IScopesData[] = null!;
    syncServicesRequested: ISyncServicesRequested[] = null!;
    activeAccounts: IActiveAccounts[] = null!;

    addedService = false;
    serviceType: 'zohoCrm' | 'zohoRecruit' = 'zohoCrm';

    constructor() {
        makeAutoObservable(this);
    }

    toggleServicePopup(state: boolean) {
        this.isServicePopupShown = state;
    }

    toggleEmailServicePopup(state: boolean) {
        this.isEmailServicePopupShown = state;
    }

    toggleRequestServicePopup(state: boolean) {
        this.isRequestServicePopupShown = state;
    }

    toggleZohoServicePopup(state: boolean) {
        this.isZohoServicePopupShown = state;
    }

    toggleEmployerConfirmationPopup(state: boolean) {
        this.isEmployerConfirmationShown = state;
    }

    setTypedNewEmployer(value: string) {
        this.typedNewEmployer = value;
    }

    setAddedService() {
        this.addedService = true;
    }

    setServiceType(type: 'zohoCrm' | 'zohoRecruit') {
        this.serviceType = type;
    }

    async getActiveAccounts() {
        try {
            const response = await OOConnect.getActiveAccounts();

            this.activeAccounts = response.data;
        } catch (error) {
            console.log('Failed to receive active accounts', error);
        }
    }

    async getCompanies(companyName: string = '') {
        try {
            const response = await OOConnect.getCompanies(companyName);
            this.companies = response.data.items;
            return response.data.items;
        } catch (error) {
            console.log('Failed to receive services data', error);
        }
    }

    async saveCompany(companyName: string) {
        try {
            const response = await OOConnect.saveCompany({
                name: companyName,
            });
            return response.data;
        } catch (error) {
            console.log('Failed to receive services data', error);
        }
    }

    async exchangeGrantToken(data: IExchangeGrantTokenIncomingData) {
        try {
            await OOConnect.exchangeGrantToken(data);
            this.setAddedService();
            return;
        } catch (error: any) {
            console.log('Failed to receive services data', error);
            throw new Error(error);
        }
    }

    async getSyncServiceRequested() {
        try {
            const response = await OOConnect.getSyncServiceRequested();
            const services = response.data?.requestedServiceCategories;

            if (services) {
                this.syncServicesRequested = services;
            }
        } catch (error: any) {
            console.log('Failed to receive sync services requested data', error);
            throw new Error(error);
        }
    }

    async sendSyncServiceRequest(data: ISyncServicesRequestIncomingData) {
        try {
            await OOConnect.sendSyncServiceRequest(data);
        } catch (error: any) {
            console.log('Failed to send sync services request data', error);
            throw new Error(error);
        }
    }
}

export default AddServiceStore;
