import { FC } from 'react';
import { Box, TableCell, TableRow } from '@mui/material';
import { StatusLine } from '@components/StatusLine/StatusLine';
import { useDataStore } from '@hooks/useDataStore';
import Zoho from '@assets/images/Zoho.png';
import ui from '@theme/common/ui.module.scss';

interface ILogTableRowProps {}

export const LogTableRow: FC<ILogTableRowProps> = () => {
    const { syncLogs } = useDataStore();

    const onClickHandler = () => {
        syncLogs.togglePopup(true);
    };

    return (
        <TableRow className={ui.tableRow} onClick={onClickHandler} sx={{ cursor: 'pointer' }}>
            <TableCell sx={{ width: '13.52%' }} className={ui.tableSellItem}>
                bb0a6143-3121-42a7-ad92-76d1353898c1
            </TableCell>
            <TableCell sx={{ width: '21.07%' }} className={ui.tableSellItem}>
                <Box className={ui.cardAppBox}>
                    <Box component="figure" className={ui.cardAppBoxFigure}>
                        <img src={Zoho} alt="" className={ui.cardAppBoxImage} />
                    </Box>
                    <Box component="span" className={ui.cardAppBoxText}>
                        Zoho
                    </Box>
                </Box>
            </TableCell>
            <TableCell sx={{ width: '9.24%' }} className={ui.tableSellItem}>
                01/10/2023 10:25PM
            </TableCell>
            <TableCell sx={{ width: '9.24%' }} className={ui.tableSellItem}>
                CocaCola
            </TableCell>
            <TableCell sx={{ width: '9.24%' }} className={ui.tableSellItem}>
                Dayan Fayfar
            </TableCell>
            <TableCell sx={{ width: '9.24%' }} className={ui.tableSellItem}>
                Shared
            </TableCell>
            <TableCell sx={{ width: '9.24%' }} className={ui.tableSellItem}>
                <StatusLine status="synced" text="Synced" />
            </TableCell>
            <TableCell sx={{ width: '9.24%' }} className={ui.tableSellItem}>
                <StatusLine status="failed" text="Failed to connect" />
            </TableCell>
            <TableCell sx={{ width: '9.64%' }} className={ui.tableSellItem}>
                Category
            </TableCell>
        </TableRow>
    );
};
