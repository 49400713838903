import { ChangeEvent, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Box } from '@mui/material';
import { Selector } from '@components/Selector/Selector';
import { Input } from '@components/Input/Input';
import { useDataStore } from '@hooks/useDataStore';
import { delay } from '@utils/delay';
import styles from './Filters.module.scss';

interface IFilterValues {
    search: string;
    service: string[];
    operation: string[];
    syncStatus: string[];
    category: string[];
}

export const Filters = () => {
    const [searchValue, setSearchValue] = useState<string>('');
    const { syncLogs } = useDataStore();

    const formik = useFormik<IFilterValues>({
        initialValues: {
            search: '',
            service: [],
            operation: [],
            syncStatus: [],
            category: [],
        },
        initialStatus: '',
        validate: () => {
            formik.setStatus('selected');
            return {};
        },
        onSubmit: () => {},
    });

    const searchHandler = (e: ChangeEvent<any>) => {
        setSearchValue(e.target.value);

        delay(500).then(() => {
            formik.setFieldValue('search', e.target.value);
        });
    };

    useEffect(() => {
        if (formik.status === 'selected') {
            console.log('formik.values', formik.values);

            syncLogs.sendFilter();
            formik.setStatus('');
        }
    }, [formik.status]);

    return (
        <Box className={styles.filters}>
            <Input
                name="search"
                value={searchValue}
                onChange={searchHandler}
                search={true}
                placeholder="Search"
                className={styles.search}
                size="small"
            />
            <Selector
                name="service"
                value={formik.values.service}
                onChange={formik.handleChange}
                placeholder="Service"
                className={styles.selector}
                size="small"
                multiple
                options={[
                    { value: 'ZOHO CRM', text: 'ZOHO CRM' },
                    { value: 'ZOHO Recruit', text: 'ZOHO Recruit' },
                    { value: 'Email', text: 'Email' },
                ]}
            />
            <Selector
                name="operation"
                value={formik.values.operation}
                onChange={formik.handleChange}
                placeholder="Operation"
                className={styles.selector}
                size="small"
                multiple
                options={[
                    { value: 'Share', text: 'Share' },
                    { value: 'Update', text: 'Update' },
                ]}
            />
            <Selector
                name="syncStatus"
                value={formik.values.syncStatus}
                onChange={formik.handleChange}
                placeholder="Sync Status"
                className={styles.selector}
                size="small"
                multiple
                options={[
                    { value: 'New', text: 'New' },
                    { value: 'In progress', text: 'In progress' },
                    { value: 'Finished', text: 'Finished' },
                    { value: 'Success', text: 'Success' },
                    { value: 'Destination error', text: 'Destination error' },
                    { value: 'Validation error', text: 'Validation error' },
                    { value: 'Internal error', text: 'Internal error' },
                ]}
            />
            <Selector
                name="category"
                value={formik.values.category}
                onChange={formik.handleChange}
                placeholder="Category"
                className={styles.selector}
                size="small"
                multiple
                options={[
                    { value: 'Organization Details', text: 'Organization Details' },
                    { value: 'Legal Data', text: 'Legal Data' },
                    { value: 'Invoice Data', text: 'Invoice Data' },
                    { value: 'Organization Address', text: 'Organization Address' },
                ]}
            />
        </Box>
    );
};
