import { makeAutoObservable } from 'mobx';

type TVertical = 'top' | 'bottom';
type THorizontal = 'left' | 'center' | 'right';
type TTypeNotification = 'error' | 'success';

interface IInitialOptions {
    state: boolean;
    type: TTypeNotification;
    message: string;
    vertical: TVertical;
    horizontal: THorizontal;
    autoHideDuration?: number;
}

interface IActionOptions {
    message: string;
    type?: TTypeNotification;
    vertical?: TVertical;
    horizontal?: THorizontal;
    autoHideDuration?: number;
}

class NotificationStore {
    options: IInitialOptions = {
        state: false,
        type: 'success',
        message: '',
        vertical: 'bottom',
        horizontal: 'center',
        autoHideDuration: 3000,
    };

    constructor() {
        makeAutoObservable(this);
    }

    sendNotification({
        message,
        type = this.options.type,
        vertical = this.options.vertical,
        horizontal = this.options.horizontal,
        autoHideDuration = this.options.autoHideDuration,
    }: IActionOptions) {
        this.options = {
            state: true,
            type,
            message,
            vertical,
            horizontal,
            autoHideDuration,
        };
    }

    closeNotification() {
        this.options.state = false;
    }
}

export default NotificationStore;
