import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';
import { UploadAvatar } from '@components/UploadAvatar/UploadAvatar';
import { Input } from '@components/Input/Input';
import { useDataStore } from '@hooks/useDataStore';
import ui from '@theme/common/ui.module.scss';
import simpleCreateCompanySchema, { SimpleCreaterCompanyFormValues } from '@schema/company/simpleCreateCompanySchema';
import { LoadingButton } from '@mui/lab';
import { getServerErrorMessage } from '@utils/errors';

type Props = {
    actionType: 'create' | 'update';
    onSuccess?: () => void;
    onFail?: () => void;
};

const ManageCompanyForm = observer(({ actionType, onSuccess, onFail }: Props) => {
    const [isLoadingCreate, setIsLoadingCreate] = useState(false);
    const { notification, companies } = useDataStore();

    const isCreate = useMemo(() => actionType === 'create', [actionType]);

    const initialValues: SimpleCreaterCompanyFormValues = {
        logo: isCreate ? undefined : (companies.companyToEdit?.logo as File),
        companyName: isCreate ? '' : companies.companyToEdit?.name ?? '',
        headline: isCreate ? '' : companies.companyToEdit?.slogan ?? '',
    };

    const handleCreate = async (values: SimpleCreaterCompanyFormValues, resetForm: () => void) => {
        try {
            const companyId = await companies.saveCompany({
                name: values.companyName,
                slogan: values.headline,
            });

            const formData = new FormData();
            formData.append('logo', values.logo as Blob);
            formData.append('id', companyId);

            await companies.uploadLogo({ id: companyId, formData });

            notification.sendNotification({ message: `"${values.companyName}" has been succesfully created.` });

            companies.getMyCompanies();

            resetForm();

            onSuccess?.();
        } catch (err) {
            notification.sendNotification({
                type: 'error',
                message: getServerErrorMessage(err),
            });

            onFail?.();
        } finally {
            setIsLoadingCreate(false);
        }
    };

    const handleUpdate = async (values: SimpleCreaterCompanyFormValues, resetForm: () => void) => {
        if (!companies.companyToEdit?.id) return null;

        try {
            await companies.updateCompany({
                id: companies.companyToEdit.id,
                data: {
                    name: values.companyName,
                    slogan: values.companyName,
                },
            });

            const formData = new FormData();
            formData.append('logo', values.logo as Blob);
            formData.append('id', companies.companyToEdit.id);

            await companies.uploadLogo({ id: companies.companyToEdit.id, formData });

            notification.sendNotification({ message: `"${values.companyName}" has been succesfully updated.` });

            companies.getMyCompanies();

            resetForm();

            onSuccess?.();
        } catch (err) {
            notification.sendNotification({
                type: 'error',
                message: getServerErrorMessage(err),
            });

            onFail?.();
        } finally {
            setIsLoadingCreate(false);
        }
    };

    const handleSubmit = async (values: SimpleCreaterCompanyFormValues, resetForm: () => void) => {
        setIsLoadingCreate(true);

        if (isCreate) {
            return await handleCreate(values, resetForm);
        }

        return await handleUpdate(values, resetForm);
    };

    useEffect(() => {
        companies.getMyCompanies();
    }, []); // eslint-disable-line

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={simpleCreateCompanySchema}
            onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
        >
            {({ values, errors, handleChange, handleBlur, touched, isValid, setFieldValue, resetForm }) => (
                <Form>
                    <Grid container maxWidth="744px" marginBottom="24px">
                        <Grid item xs={12}>
                            <Input
                                name="companyName"
                                onChange={handleChange}
                                id="my-companies-company-name"
                                value={values.companyName}
                                label="company name *"
                                error={errors.companyName}
                                touched={touched.companyName}
                                onBlur={handleBlur}
                            />
                        </Grid>

                        <Grid>
                            <UploadAvatar
                                img={values.logo}
                                name="logo"
                                label="Company logo *"
                                id="my-companies-company-logo"
                                onChange={(event) => {
                                    const target = event.target as HTMLInputElement;
                                    const file: File = (target.files as FileList)[0];
                                    setFieldValue('logo', file);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Input
                                name="headline"
                                onChange={handleChange}
                                id="my-companies-company-headline"
                                value={values.headline}
                                label="Company headline *"
                                error={errors.headline}
                                touched={touched.headline}
                                onBlur={handleBlur}
                            />
                        </Grid>
                    </Grid>
                    <LoadingButton
                        disabled={!isValid}
                        variant="contained"
                        type="submit"
                        fullWidth
                        loading={isLoadingCreate}
                        className={ui.buttonOutlinedPrimary}
                    >
                        {isCreate ? 'Create company' : 'Update company'}
                    </LoadingButton>
                </Form>
            )}
        </Formik>
    );
});

export default ManageCompanyForm;
