import { Form, Formik } from 'formik';
import { Input } from '@components/Input/Input';
import profileInfoSchema, { ProfileInfoFormValues } from './schema';
import { FormNavigation } from '../../FormNavigation/FormNavigation';
import { useFormContext } from '@pages/Onboarding/context/FormContext';
import { UploadAvatar } from '@components/UploadAvatar/UploadAvatar';
import { Selector } from '@components/Selector/Selector';
import { AdditionalFormInfo } from '../../AdditionalFormInfo/AdditionalFormInfo';
import { useState } from 'react';
import OOApi, { type User } from '@services/OOApi';
import { useDataStore } from '@hooks/useDataStore';
import { useNavigate } from 'react-router-dom';
import { ListRoutes } from '@components/AppRouter/routes';

export const ProfileInfoForm = () => {
    const navigation = useNavigate();
    const { formValues, setFormValues } = useFormContext();
    const { notification } = useDataStore();

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const initialValues: ProfileInfoFormValues = {
        file: formValues.file,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        gender: formValues.gender,
        email: formValues.email,
    };

    const submitForm = async (values: ProfileInfoFormValues) => {
        setIsLoadingSubmit(true);

        setFormValues((prev) => ({
            ...prev,
            ...values,
        }));

        const updateAccount = OOApi.updateAccount({
            firstName: values.firstName,
            lastName: values.lastName,
            gender: values.gender as User['gender'],
            email: values.email,
            role: 'Agency',
        });

        const formData = new FormData();
        formData.append('Photo', values.file as Blob);

        const uploadAvatar = values.file ? OOApi.updateAvatar(formData) : Promise.resolve();

        Promise.all([updateAccount, uploadAvatar])
            .then(() => {
                return navigation(ListRoutes.SyncService);
            })
            .catch(() => {
                notification.sendNotification({ type: 'error', message: 'There was an error. Please try again.' });
                setIsLoadingSubmit(false);
            });
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={profileInfoSchema}
            onSubmit={submitForm}
        >
            {({ values, errors, handleChange, handleBlur, touched, setFieldValue }) => (
                <Form>
                    <UploadAvatar
                        name="avatar"
                        id="onboard-avatar"
                        label="Profile Photo"
                        {...(formValues.file ? { img: formValues.file as Blob } : {})}
                        onChange={(e) => {
                            const target = e.target as HTMLInputElement;
                            const file: File = (target.files as FileList)[0];
                            setFieldValue('file', file);
                        }}
                    />

                    <Input
                        name="firstName"
                        onChange={handleChange}
                        id="onboard-first-name"
                        value={values.firstName}
                        label="First name *"
                        error={errors.firstName}
                        touched={touched.firstName}
                        onBlur={handleBlur}
                    />

                    <Input
                        name="lastName"
                        onChange={handleChange}
                        id="onboard-last-name"
                        value={values.lastName}
                        label="Last name *"
                        error={errors.lastName}
                        touched={touched.lastName}
                        onBlur={handleBlur}
                    />

                    <Selector
                        name="gender"
                        onChange={handleChange}
                        value={values.gender}
                        touched={touched.gender}
                        error={errors.gender}
                        id="onboard-gender"
                        label="Gender *"
                        options={[
                            { value: 'Male', text: 'Male' },
                            { value: 'Female', text: 'Female' },
                            { value: 'Other', text: 'Other' },
                        ]}
                    />

                    <Input
                        name="email"
                        onChange={handleChange}
                        id="onboard-email"
                        value={values.email}
                        label="Email *"
                        error={errors.email}
                        touched={touched.email}
                        onBlur={handleBlur}
                        helperText="Used to recover your account and get information & terms related to OnlyOnce."
                    />

                    <AdditionalFormInfo />

                    <FormNavigation isLoading={isLoadingSubmit} />
                </Form>
            )}
        </Formik>
    );
};
