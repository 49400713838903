import { memo, ChangeEvent, FC, useState } from 'react';
import { Box, InputLabel, FormHelperText, TextareaAutosize } from '@mui/material';
import styles from './Textarea.module.scss';

interface ITextarea {
    value: string;
    name: string;
    maxLength: number;
    setFieldValue: (key: string, value: string) => void;
    id?: string;
    label?: string;
    minRows?: number;
    maxRows?: number;
    className?: string;
    placeholder?: string;
}

export const Textarea: FC<ITextarea> = memo(
    ({ name, id, label, minRows = 3, maxRows = 3, maxLength, className, placeholder = '', setFieldValue }) => {
        const [value, setValue] = useState<string>('');
        const [counter, setCounter] = useState<number>(maxLength);

        const onChangeHandler = (e: ChangeEvent<any>) => {
            const value = e.target.value;

            setValue((prevState) => {
                if (maxLength < value.length) {
                    return prevState;
                }

                setCounter(() => {
                    return maxLength - value.length;
                });

                return value;
            });
        };

        const onBlurHandle = () => {
            setFieldValue(name, value);
        };

        return (
            <Box className={`${styles.textareaWrapper} ${className}`}>
                {label && (
                    <InputLabel htmlFor={id} className={styles.label}>
                        {label}
                    </InputLabel>
                )}
                <TextareaAutosize
                    value={value}
                    name={name}
                    onChange={onChangeHandler}
                    onBlur={onBlurHandle}
                    aria-label={label}
                    id={id}
                    minRows={minRows}
                    maxRows={maxRows}
                    placeholder={placeholder}
                    className={styles.textarea}
                />
                <FormHelperText className={styles.helperText}>{counter} characters</FormHelperText>
            </Box>
        );
    },
);
