import { FC, ReactElement } from 'react';
import { Modal, Box, Button, Fade, Paper, Typography } from '@mui/material';
import { ReactComponent as ModalCloseIcon } from '@assets/icons/modal-close.svg';
import styles from './Popup.module.scss';

type TSizeContainer = 'middle' | 'large';
type TPosition = 'center' | 'right';

interface PopupProps {
    title: string;
    state: boolean;
    onClose: () => void;
    children: JSX.Element | JSX.Element[];
    renderFooter?: ReactElement;
    sizeContainer?: TSizeContainer;
    availableCloseButton?: boolean;
    position?: TPosition;
    hideBackdrop?: boolean;
    disableScrollLock?: boolean;
}

export const Popup: FC<PopupProps> = ({
    title,
    state,
    onClose,
    children,
    renderFooter,
    sizeContainer = 'middle',
    availableCloseButton = true,
    position = 'center',
    hideBackdrop = false,
    disableScrollLock = false,
}) => {
    const containerSizes = {
        middle: '400px',
        large: '790px',
    };

    const positionSelectors = {
        center: styles.popupCenter,
        right: styles.popupRight,
    };

    return (
        <Modal
            open={state}
            onClose={onClose}
            className={`${styles.popup} ${positionSelectors[position]}`}
            hideBackdrop={hideBackdrop}
            disableScrollLock={disableScrollLock}
            disableAutoFocus={true}
        >
            <Fade in={state} timeout={250}>
                <Paper
                    className={`${styles.wrapper} ${hideBackdrop ? styles.wrapperBorder : ''}`}
                    sx={{
                        width: containerSizes[sizeContainer],
                    }}
                >
                    <Box className={styles.header}>
                        <Typography variant="h5" className={styles.title}>
                            {title}
                        </Typography>
                        {availableCloseButton && (
                            <Button onClick={onClose} className={styles.closeButton}>
                                <ModalCloseIcon className={styles.closeIcon} />
                            </Button>
                        )}
                    </Box>
                    <Box
                        className={styles.body}
                        sx={{
                            maxHeight: renderFooter ? '70vh' : '82vh',
                        }}
                    >
                        {children}
                    </Box>
                    {renderFooter && <Box className={styles.footer}>{renderFooter}</Box>}
                </Paper>
            </Fade>
        </Modal>
    );
};
