import { NavLink } from 'react-router-dom';
import { List, ListItem, ListItemButton } from '@mui/material';
import { ListRoutes } from '@components/AppRouter/routes';
import { useDataStore } from '@hooks/useDataStore';
import { ReactComponent as ClockIcon } from '@assets/icons/clock.svg';
import { ReactComponent as EnvelopeIcon } from '@assets/icons/envelope.svg';
import { ReactComponent as InfoCircleIcon } from '@assets/icons/info-circle.svg';
import styles from './Navbar.module.scss';

export const Navbar = () => {
    const { writeToUs } = useDataStore();

    const openPopupWriteToUs = () => {
        writeToUs.togglePopup(true);
    };

    const defineActiveClass = ({ isActive }: { isActive: boolean }) => {
        return isActive ? `${styles.navItemLinkActive} ${styles.navItemLink}` : styles.navItemLink;
    };

    return (
        <nav className={styles.navbar}>
            <List className={styles.container}>
                <ListItem disablePadding>
                    <NavLink to={ListRoutes.SyncService} className={defineActiveClass}>
                        <ClockIcon className={styles.navItemIcon} />
                        Sync Services
                    </NavLink>
                </ListItem>
                <ListItem disablePadding>
                    <NavLink to={ListRoutes.SyncLog} className={defineActiveClass}>
                        <InfoCircleIcon className={styles.navItemIcon} />
                        Sync Log
                    </NavLink>
                </ListItem>
                <ListItem disablePadding className={styles.bottomNavItem}>
                    <ListItemButton onClick={openPopupWriteToUs} disableRipple={true} className={styles.navItemLink}>
                        <EnvelopeIcon className={styles.navItemIcon} />
                        Write to us
                    </ListItemButton>
                </ListItem>
            </List>
        </nav>
    );
};
