import { Box, Typography } from '@mui/material';
import { Header } from '@components/Header/Header';
import { Navbar } from '@components/Navbar/Navbar';
import { WriteToUsPopup } from '@components/WriteToUsPopup/WriteToUsPopup';
import { LogTable } from './components/LogTable/LogTable';
import { Filters } from './components/Filters/Filters';
import { LogInfoPopup } from './components/LogInfoPopup/LogInfoPopup';
import ui from '@theme/common/ui.module.scss';

export const SyncLog = () => {
    return (
        <Box className={ui.layoutContainer}>
            <Box className={ui.layoutHeader}>
                <Header />
            </Box>
            <Box className={ui.layoutNavbar}>
                <Navbar />
            </Box>
            <Box className={ui.layoutContent}>
                <Typography marginBottom="32px" variant="h1" className={ui.title}>
                    Sync Log
                </Typography>
                <Filters />
                <LogTable />
            </Box>
            <LogInfoPopup />
            <WriteToUsPopup />
        </Box>
    );
};
