import { useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, AppBar, Toolbar, Button, Paper, ButtonBase, Avatar } from '@mui/material';
import { useDataStore } from '@hooks/useDataStore';
import { Popper } from '@components/Popper/Popper';
import { ListRoutes } from '@components/AppRouter/routes';
import { ReactComponent as Logotype } from '@assets/icons/logotype.svg';
import { ReactComponent as BillFilledIcon } from '@assets/icons/bell-filled.svg';
import { ReactComponent as AccountIcon } from '@assets/icons/account.svg';
import { ReactComponent as LogoutIcon } from '@assets/icons/logout.svg';
import { ReactComponent as ChervonIcon } from '@assets/icons/chevron-up.svg';
import styles from './Header.module.scss';

export const Header = observer(() => {
    const { pathname } = useLocation();
    const navigation = useNavigate();
    const { authorization, user } = useDataStore();
    const [activeLinkByPathname, setActiveLinkByPathname] = useState<boolean>(false);

    const onSignOut = async () => {
        await authorization.signOut();
        navigation(ListRoutes.Login);
    };

    useEffect(() => {
        setActiveLinkByPathname(ListRoutes.Account === pathname);
    }, [pathname]);

    useEffect(() => {
        user.getAccount();
    }, []);

    const getAvatar = useMemo(() => {
        const avatar = user.uploadedAvatar;

        if (!avatar) return null;

        if (avatar instanceof Blob) {
            return URL.createObjectURL(avatar);
        }

        if (typeof avatar === 'string') {
            return avatar;
        }
    }, [user.uploadedAvatar]);

    return (
        <Box className={styles.header} sx={{ flexGrow: 1 }}>
            <AppBar position="static" className={styles.appBar} color="transparent">
                <Toolbar className={styles.toolBar}>
                    <Box fontSize="0" sx={{ flexGrow: 1 }}>
                        <Logotype className={styles.logotype} />
                    </Box>
                    <Box className={styles.notificationBox}>
                        <BillFilledIcon className={styles.billFilledIcon} />
                        <span className={styles.notificationBall}></span>
                    </Box>
                    <Popper
                        className={styles.popper}
                        classActiveButton={styles.accountMenuActiveButton}
                        renderButton={
                            <ButtonBase component="button" disableRipple={true} className={styles.accountMenuButton}>
                                {getAvatar && (
                                    <Avatar className={styles.accountMenuAvatar} src={getAvatar} alt="Avatar" />
                                )}
                                {!getAvatar && (
                                    <Box className={styles.accountMenuAvatarIcon}>
                                        <AccountIcon width="14px" />
                                    </Box>
                                )}
                                <ChervonIcon className={styles.accountMenuButtonIcon} />
                            </ButtonBase>
                        }
                        renderDropdown={
                            <Paper className={styles.accountMenuDropdown}>
                                <Button
                                    fullWidth
                                    component="a"
                                    href="/account"
                                    startIcon={<AccountIcon width="14px" className={styles.accountMenuLinkIcon} />}
                                    className={`${styles.accountMenuLink} ${activeLinkByPathname ? 'active' : ''}`}
                                    sx={{
                                        '& .MuiButton-startIcon': {
                                            marginLeft: '0',
                                            marginRight: '15px',
                                        },
                                        '&.MuiButton-root.active': {
                                            color: 'var(--c-primary-sky-blue)',
                                        },
                                        '&.active .MuiButton-startIcon svg': {
                                            fill: 'var(--c-primary-sky-blue)',
                                        },
                                    }}
                                >
                                    My Account
                                </Button>
                                <Button
                                    fullWidth
                                    startIcon={<LogoutIcon width="16px" className={styles.accountMenuLinkIcon} />}
                                    className={styles.accountMenuLink}
                                    onClick={onSignOut}
                                    sx={{
                                        '& .MuiButton-startIcon': {
                                            marginLeft: '0',
                                            marginRight: '15px',
                                        },
                                    }}
                                >
                                    Log Out
                                </Button>
                            </Paper>
                        }
                    />
                </Toolbar>
            </AppBar>
        </Box>
    );
});
