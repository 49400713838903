import { SyntheticEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { Snackbar } from '@mui/material';
import { useDataStore } from '@hooks/useDataStore';
import { ReactComponent as SuccessIcon } from '@assets/icons/success.svg';
import { ReactComponent as ErrorIcon } from '@assets/icons/info-circle.svg';
import styles from './Notification.module.scss';

export const Notification = observer(() => {
    const { notification } = useDataStore();
    const { type, message, state, vertical, horizontal, autoHideDuration } = notification.options;

    const onCloseHandler = (e: SyntheticEvent<any> | Event, reason: string) => {
        if (reason === 'clickaway') {
            return;
        }

        notification.closeNotification();
    };

    const styling = {
        success: {
            actionIcon: <SuccessIcon className={styles.successIcon} />,
        },
        error: {
            actionIcon: <ErrorIcon className={styles.errorIcon} />,
        },
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={state}
            onClose={onCloseHandler}
            message={message}
            key={`${horizontal}_${vertical}`}
            autoHideDuration={autoHideDuration}
            action={styling[type].actionIcon}
            sx={{
                '& .MuiPaper-root': {
                    flexDirection: 'row-reverse',
                    justifyContent: 'center',
                    minWidth: '400px',
                    height: '48px',
                    borderRadius: '10px',
                    backgroundColor: '#00202E',
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
                },
                '& .MuiSnackbarContent-action': {
                    margin: '0',
                    paddingLeft: '0',
                },
                '& .MuiSnackbarContent-message': {
                    padding: '0',
                    fontSize: '16px',
                    fontWeight: '600',
                },
            }}
        />
    );
});
