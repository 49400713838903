import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { NoPhotography, Delete, Edit } from '@mui/icons-material';
import { ICompaniesData } from '@store/Companies.store';
import { useDataStore } from '@hooks/useDataStore';

type Props = {
    index: number;
    id: ICompaniesData['id'];
    name: ICompaniesData['name'];
    slogan: ICompaniesData['slogan'];
    logo: ICompaniesData['logo'];
};

const CompanyCard = ({ index, id, name, slogan, logo }: Props) => {
    const { companies } = useDataStore();

    const isFirstItem = index === 0;

    const handleDelete = () => {
        companies.setCompanyToDelete({ id, name });
        companies.setIsDeleteCompanyPopupShown(true);
    };

    const handleEdit = () => {
        companies.setCompanyToEdit({ id });
        companies.setIsEditCompanyPopupShown(true);
    };

    return (
        <Box
            {...(isFirstItem
                ? {
                      borderTop: isFirstItem ? '0.5px solid #CAD0D9' : '20px solid #333',
                  }
                : {})}
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: '0.5px solid #CAD0D9',
                paddingY: '15px',
            }}
        >
            <Box sx={{ display: 'flex' }}>
                {logo ? (
                    <Box
                        sx={{
                            width: '48px',
                            height: '48px',
                            borderRadius: '8px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: `url(${logo})`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    />
                ) : (
                    <Box
                        sx={{
                            width: '48px',
                            height: '48px',
                            borderRadius: '8px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#CAD0D9',
                            color: 'white',
                        }}
                    >
                        <NoPhotography />
                    </Box>
                )}
                <Box sx={{ ml: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: '600', lineHeight: '18px' }}>
                        {name}
                    </Typography>
                    {slogan && (
                        <Typography variant="body1" sx={{ fontSize: '12px', color: '#8D99A7', fontWeight: '400' }}>
                            {slogan}
                        </Typography>
                    )}
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '5px',
                }}
            >
                <Tooltip arrow placement="top" title={`Edit company "${name}"`}>
                    <IconButton
                        aria-label={`Edit company ${name}.`}
                        onClick={handleEdit}
                        sx={{ width: '40px', height: '40px' }}
                    >
                        <Edit />
                    </IconButton>
                </Tooltip>

                <Tooltip arrow placement="top" title={`Delete company "${name}"`}>
                    <IconButton
                        aria-label={`Delete company ${name}.`}
                        onClick={handleDelete}
                        sx={{ width: '40px', height: '40px' }}
                    >
                        <Delete />
                    </IconButton>
                </Tooltip>
            </Box>

            {/* <DeleteCompanyPopup key={id} id={id} name={name} /> */}
        </Box>
    );
};

export default CompanyCard;
