import { createContext } from 'react';
import AuthorizationStore from './Authorization.store';
import GeolocationStore from './Geolocation.store';
import UserStore from './User.store';
import SyncServicesStore from './SyncServices.store';
import AddServiceStore from './AddService.store';
import NotificationStore from './Notification.store';
import WriteToUsStore from './WriteToUs.store';
import SyncLogsStore from './SyncLogs.store';
import CompaniesStore from './Companies.store';

interface IRootStore {
    authorization: AuthorizationStore;
    companies: CompaniesStore;
    geolocation: GeolocationStore;
    user: UserStore;
    syncServices: SyncServicesStore;
    addService: AddServiceStore;
    notification: NotificationStore;
    writeToUs: WriteToUsStore;
    syncLogs: SyncLogsStore;
}

export const rootStore: IRootStore = {
    authorization: new AuthorizationStore(),
    companies: new CompaniesStore(),
    geolocation: new GeolocationStore(),
    user: new UserStore(),
    syncServices: new SyncServicesStore(),
    addService: new AddServiceStore(),
    notification: new NotificationStore(),
    writeToUs: new WriteToUsStore(),
    syncLogs: new SyncLogsStore(),
};

export const DataStoreContext = createContext(rootStore);
