export enum ListRoutes {
    Login = '/',
    Onboarding = '/onboarding',
    Account = '/account',
    MyAccount = '/account/my-account',
    MyCompanies = '/account/my-companies',
    EditCompany = '/edit-company',
    SyncService = '/sync-services',
    SyncLog = '/sync-log',
    NotFound = '*',
}
