import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button } from '@mui/material';
import { useFormik } from 'formik';
import { Popup } from '@components/Popup/Popup';
import { Selector } from '@components/Selector/Selector';
import { Input } from '@components/Input/Input';
import { Textarea } from '@components/Textarea/Textarea';
import { UploadFile } from '@components/UploadFile/UploadFile';
import { useDataStore } from '@hooks/useDataStore';
import { validation } from '@utils/validation';
import { Errors } from '@constants/errors';
import { faultProvider } from '@utils/faultProvider';
import ui from '@theme/common/ui.module.scss';
import styles from './WriteToUsPopup.module.scss';

interface IInitialValues {
    issueType: string;
    title: string;
    message: string;
    screenshot: Blob | string;
}

export const WriteToUsPopup = observer(() => {
    const { validateFileSize, validateLength, validateRequired } = validation();
    const { getLimitationFault } = faultProvider();
    const { writeToUs, notification } = useDataStore();

    const validateForm = (values: IInitialValues) => {
        const errors: Record<string, string> = {};

        if (!validateRequired(values.issueType)) {
            errors.issueType = Errors.RequiredField;
        }

        if (!validateLength(values.title, 200)) {
            errors.title = getLimitationFault('title', 200);
        }

        if (!validateLength(values.message, 500)) {
            errors.message = getLimitationFault('message', 500);
        }

        if (!validateRequired(values.message)) {
            errors.message = Errors.RequiredField;
        }

        if (!validateFileSize(values.screenshot, 5)) {
            errors.screenshot = Errors.UploadFile;
        }

        return errors;
    };

    const onSubmitForm = (values: IInitialValues) => {
        const formData = new FormData();

        formData.append('issue_type', values.issueType);
        formData.append('title', values.title);
        formData.append('message', values.message);
        formData.append('screenshot', values.screenshot);

        writeToUs.sendFeedback(formData).then(() => {
            writeToUs.togglePopup(false);
            notification.sendNotification({ message: 'The feedback was sent' });
        });
    };

    const formik = useFormik({
        initialValues: {
            issueType: '',
            title: '',
            message: '',
            screenshot: '',
        },
        onSubmit: onSubmitForm,
        validate: validateForm,
    });

    const handleClose = useCallback(() => {
        writeToUs.togglePopup(false);
        formik.resetForm();
    }, []);

    const PopupFooter = () => (
        <Box className={ui.popupFooter}>
            <Box className={ui.buttonGroup}>
                <Button
                    type="submit"
                    form="writeToUsForm"
                    variant="contained"
                    disabled={!(formik.dirty && formik.isValid)}
                    className={ui.buttonContained}
                    sx={{
                        '&.MuiButtonBase-root.Mui-disabled': {
                            backgroundColor: 'var(--c-grey-normal)',
                            color: 'var(--c-grey-dark)',
                        },
                    }}
                >
                    Send
                </Button>
            </Box>
        </Box>
    );

    return (
        <Popup
            state={writeToUs.isPopupOpened}
            onClose={handleClose}
            title="Write to us your feedback"
            renderFooter={<PopupFooter />}
        >
            <form id="writeToUsForm" onSubmit={formik.handleSubmit}>
                <Selector
                    name="issueType"
                    onChange={formik.handleChange}
                    value={formik.values.issueType}
                    id="write-to-us-issue-type"
                    label="Issue type *"
                    className={styles.input}
                    options={[
                        { value: 'defect', text: 'Defect' },
                        { value: 'improvement', text: 'Improvement' },
                        { value: 'wish', text: 'Wish' },
                        { value: 'question ', text: 'Question ' },
                    ]}
                />
                <Input
                    name="title"
                    onChange={formik.handleChange}
                    id="write-to-us-title"
                    value={formik.values.title}
                    label="Title"
                    error={formik.errors.title}
                    touched={formik.touched.title}
                    onBlur={formik.handleBlur}
                    className={styles.input}
                />
                <Textarea
                    name="message"
                    value={formik.values.message}
                    id="write-to-us-message"
                    label="Message *"
                    minRows={9}
                    maxRows={9}
                    maxLength={500}
                    setFieldValue={formik.setFieldValue}
                    className={styles.input}
                />
                <UploadFile
                    name="screenshot"
                    id="write-to-us-screenshot"
                    label="Attach screenshot"
                    error={formik.errors.screenshot}
                    setFieldValue={formik.setFieldValue}
                    className={styles.uploadFile}
                />
            </form>
        </Popup>
    );
});
