import { Typography } from '@mui/material';
import styles from './Title.module.scss';
import { useEffect, useState } from 'react';
import { useFormContext } from '@pages/Onboarding/context/FormContext';

export const Title = () => {
    const { step } = useFormContext();
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');

    useEffect(() => {
        switch (step) {
            case 0:
                setTitle('Enter your license key');
                setSubtitle(
                    'If you want to enter as a company, you have to fill in your license key given to you by Only Once.',
                );
                break;
            case 1:
                setTitle('Tell us about you');
                setSubtitle(
                    'Let’s create your profile. All your business applications will always be up to date, effortless and completely automated.',
                );
                break;
            case 2:
                setTitle('Tell us about your company');
                setSubtitle(
                    'Let’s create an company profile. All your business applications will always be up to date, effortless and completely automated.',
                );
                break;
        }
    }, [step]);

    return (
        <>
            <Typography className={styles.title} variant="h1">
                {title}
            </Typography>
            <Typography className={styles.description} paragraph={true}>
                {subtitle}
            </Typography>
        </>
    );
};
