import { makeAutoObservable } from 'mobx';
import OOApi from '@services/OOApi';

class WriteToUsStore {
    isPopupOpened: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    togglePopup(state: boolean) {
        this.isPopupOpened = state;
    }

    async sendFeedback(formData: FormData) {
        try {
            const response = await OOApi.sendFeedback(formData);

            console.log('response', response);

            return response;
        } catch (error: any) {
            console.error('Faild to send feedback data', error);
            throw new Error(error);
        }
    }
}

export default WriteToUsStore;
