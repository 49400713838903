import styles from './LoginHero.module.scss';
import logo from '@assets/icons/logo.svg';

export const LoginHero = () => (
    <div className={styles.hero}>
        <div className={styles.logo}>
            <img src={logo} alt="onlyonce logo" />

            <div className={styles.title}>
                <div className={styles.name}>Only Once</div>
                <div className={styles.service}>Connect</div>
            </div>
        </div>

        <div className={styles.desc}>
            Stay connected to your business relations, seamlessly synced with your business applications
        </div>

        <div className={styles.details}>
            <span>Want more details? </span>
            <a
                href="https://www.onlyonce.com/recruitment-agencies/"
                target="_blank"
                rel="noreferrer"
                className={styles.detailsLink}
            >
                Find out more
            </a>
        </div>
    </div>
);
