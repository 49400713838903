import axios from 'axios';
import { MuiTelInputCountry } from 'mui-tel-input';
import config from '@config/config';

interface Geo {
    ip: string;
    countrycode: MuiTelInputCountry;
    countryname: string;
    registry: string;
    spam: boolean;
    tor: boolean;
}

class GeolocationService {
    async fetchCountryCodeGetting() {
        return await axios.get<Geo>(config.GEOLOCATION_API ?? '');
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new GeolocationService();
