import { ChangeEvent, FC, memo } from 'react';
import { Radio, FormControl, FormLabel, RadioGroup, Typography } from '@mui/material';
import styles from './TextRadio.module.scss';

interface IOptions {
    id: string;
    value: string;
    text: string;
}

interface ITextRadioProps {
    name: string;
    label?: string;
    value: string;
    options: IOptions[];
    onChange: (e: ChangeEvent<any>) => void;
}

export const TextRadio: FC<ITextRadioProps> = memo(({ name, value, options, label, onChange }) => {
    const TextRadioContent = ({ text }: { text: string }) => {
        return <Typography variant="caption">{text}</Typography>;
    };

    return (
        <FormControl className={styles.textRadio}>
            {label && <FormLabel className={styles.textRadioLabel}>{label}</FormLabel>}
            <RadioGroup className={styles.textRadioContent} name={name} value={value} onChange={onChange}>
                {options.map((option) => (
                    <Radio
                        key={option.id}
                        icon={<TextRadioContent text={option.text} />}
                        checkedIcon={<TextRadioContent text={option.text} />}
                        disableRipple={true}
                        value={option.value}
                        sx={{
                            '&': {
                                justifyContent: 'flex-start',
                                height: '42px',
                                padding: '5px 16px',
                                borderRadius: '0',
                                transitionDuration: '200ms',
                            },
                            '& .MuiTypography-root': {
                                color: 'var(--c-primary-black)',
                                fontSize: '18px',
                                transitionDuration: '200ms',
                            },
                            '&.Mui-checked': {
                                backgroundColor: 'var(--c-primary-sky-blue)',
                            },
                            '&.Mui-checked .MuiTypography-root': {
                                color: 'var(--c-primary-white)',
                            },
                        }}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
});
