import * as yup from 'yup';

const profileInfoSchema = yup
    .object()
    .shape({
        file: yup.mixed<File>().nullable(),
        firstName: yup
            .string()
            .min(2, 'First name should be at least 2 characters long.')
            .max(50, 'First name should not be longer than 50 characters.')
            .required('First name is required.'),
        lastName: yup
            .string()
            .min(2, 'Last name should be at least 2 characters long.')
            .max(50, 'Last name should not be longer than 50 characters.')
            .required('Last name is required.'),
        gender: yup.string().nullable().notOneOf([null], 'Gender is required.'),
        email: yup
            .string()
            .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Email is not valid.')
            .required('Email is required.'),
        country: yup.string(),
    })
    .defined();

export type ProfileInfoFormValues = yup.InferType<typeof profileInfoSchema>;

export default profileInfoSchema;
