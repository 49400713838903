import { Box, Tab, Tabs } from '@mui/material';
import { Header } from '@components/Header/Header';
import { Navbar } from '@components/Navbar/Navbar';
import { WriteToUsPopup } from '@components/WriteToUsPopup/WriteToUsPopup';
import ui from '@theme/common/ui.module.scss';
import { useEffect, useState } from 'react';
import { a11yTabProps } from './utils/a11yProps';
import TabPanel from './components/TabPanel';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { ListRoutes } from '@components/AppRouter/routes';

export const Account = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const tabToUrl: { [key: number]: string } = {
        0: ListRoutes.MyAccount,
        1: ListRoutes.MyCompanies,
    };

    useEffect(() => {
        if (location.pathname.includes(ListRoutes.MyAccount)) {
            return setCurrentTab(0);
        } else if (location.pathname.includes(ListRoutes.MyCompanies)) {
            return setCurrentTab(1);
        } else {
            // Default to My Account outlet
            return navigate(tabToUrl[0]);
        }
    }, [location.pathname, currentTab]); // eslint-disable-line

    const setUrl = (index: number) => {
        if (tabToUrl[index]) {
            navigate(tabToUrl[index]);
        }
    };

    return (
        <Box className={ui.layoutContainer}>
            <Box className={ui.layoutHeader}>
                <Header />
            </Box>
            <Box className={ui.layoutNavbar}>
                <Navbar />
            </Box>
            <Box className={ui.layoutContent}>
                <Tabs value={currentTab} onChange={(_, value: number) => setUrl(value)} aria-label="basic tabs example">
                    <Tab label="My account" {...a11yTabProps(0)} />
                    <Tab label="My companies" {...a11yTabProps(1)} />
                </Tabs>

                <TabPanel index={0} value={currentTab}>
                    <Outlet />
                </TabPanel>

                <TabPanel index={1} value={currentTab}>
                    <Outlet />
                </TabPanel>
            </Box>
            <WriteToUsPopup />
        </Box>
    );
};
