const sizeLarge = {
    '&': {
        height: '48px',
        paddingLeft: '0px',
        borderRadius: '12px',
        backgroundColor: 'var(--c-grey-light)',
    },
    '& .MuiInputBase-input': {
        padding: '0px 18px',
        color: 'var(--c-primary-black)',
        fontSize: '18px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--c-grey-normal)',
    },
    '&.Mui-error': {
        backgroundColor: '#FCEAE6',
    },
    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
    },
};

const sizeSmall = {
    '&': {
        height: '40px',
        paddingLeft: '0px',
        borderRadius: '8px',
        backgroundColor: 'var(--c-grey-light)',
    },
    '& .MuiInputBase-input': {
        padding: '0px 18px',
        color: 'var(--c-primary-black)',
        fontSize: '18px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--c-grey-normal)',
    },
    '&.Mui-error': {
        backgroundColor: '#FCEAE6',
    },
    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
    },
};

export const themeProvider = {
    large: sizeLarge,
    small: sizeSmall,
};
