import { FC, memo } from 'react';
import { FormGroup, FormLabel, Switch, Link, SelectChangeEvent } from '@mui/material';
import styles from './Switcher.module.scss';

interface ISwitcherProps {
    onChange: (e: SelectChangeEvent<string>) => void;
    id: string;
    checked: boolean;
    name: string;
    label?: {
        text: string;
        textLink: string;
        url: string;
    };
}

export const Switcher: FC<ISwitcherProps> = memo(({ checked, name, onChange, id, label }) => {
    return (
        <FormGroup className={styles.wrapper}>
            <Switch
                id={id}
                onChange={onChange}
                disableRipple={true}
                checked={checked}
                name={name}
                sx={{
                    '&': {
                        position: 'relative',
                        width: '60px',
                        height: '34px',
                        padding: '0',
                    },
                    '& .MuiButtonBase-root': {
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        justifyContent: 'flex-start',
                        width: '100%',
                        height: '100%',
                        padding: '0',
                        transform: 'translateX(5px)',
                    },
                    '& .MuiButtonBase-root.Mui-checked': {
                        transform: 'translateX(30px)',
                    },
                    '& .MuiSwitch-thumb': {
                        width: '24px',
                        height: '24px',
                    },
                    '& .MuiSwitch-track': {
                        borderRadius: '34px',
                        backgroundColor: '#B2B4B7',
                        opacity: '1',
                        border: '1px solid #D3D3D3',
                    },
                    '& .Mui-checked .MuiSwitch-thumb': {
                        backgroundColor: 'var(--c-primary-white)',
                    },
                    '& .MuiButtonBase-root.Mui-checked + .MuiSwitch-track': {
                        opacity: '1',
                    },
                }}
            />
            {label && (
                <FormLabel htmlFor={id} className={styles.label}>
                    {`${label.text} `}
                    <Link target={'_blank'} href={label.url} className={styles.link}>
                        {label.textLink}
                    </Link>
                </FormLabel>
            )}
        </FormGroup>
    );
});
