import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Formik } from 'formik';
import { Grid, Typography, Button } from '@mui/material';
import { UploadAvatar } from '@components/UploadAvatar/UploadAvatar';
import { Input } from '@components/Input/Input';
import { Selector } from '@components/Selector/Selector';
import { PhoneNumberInput } from '@components/PhoneNumberInput/PhoneNumberInput';
import { useDataStore } from '@hooks/useDataStore';
import { validation } from '@utils/validation';
import { Errors } from '@constants/errors';
import ui from '@theme/common/ui.module.scss';
import styles from './index.module.scss';
import { Box } from '@mui/system';
import { User } from '@services/OOApi';

type ExtendedAccountType = Partial<User> & {
    file: Blob | null;
    language: string;
};

const MyAccount = observer(() => {
    const { validateEmail, validateRequired } = validation();
    const { user, notification } = useDataStore();

    const initialValues: ExtendedAccountType = {
        file: null,
        email: user.user?.email || '',
        firstName: user.user?.firstName || '',
        lastName: user.user?.lastName || '',
        gender: (user.user?.gender || '') as User['gender'],
        language: 'en',
        phone: user.user?.phone,
        linkedIn: null,
    };

    const validateValues = (values: ExtendedAccountType) => {
        const errors: Record<string, string> = {};

        if (!validateRequired(values.firstName ?? '')) {
            errors.firstName = Errors.RequiredField;
        }

        if (!validateRequired(values.lastName ?? '')) {
            errors.lastName = Errors.RequiredField;
        }

        if (!validateRequired(values.email ?? '')) {
            errors.email = Errors.RequiredField;
        }

        if (!validateEmail(values.email ?? '')) {
            errors.email = Errors.Email;
        }

        return errors;
    };

    const onSubmitForm = (values: ExtendedAccountType) => {
        const { file, language, phone: phoneNumber, ...data } = values;

        user.updateAccount(data).then(() => {
            notification.sendNotification({ message: 'Personal data updated' });
        });

        if (file) {
            const formData = new FormData();
            formData.append('Photo', file);
            user.updateAvatar(formData);
        }
    };

    const getAvatar = useMemo(() => {
        const avatar = user.uploadedAvatar;

        if (!avatar) return null;

        if (avatar instanceof Blob) {
            return URL.createObjectURL(avatar);
        }

        if (typeof avatar === 'string') {
            return avatar;
        }
    }, [user.uploadedAvatar]);

    return (
        <Box
            sx={{
                background: '#ffffff',
                padding: '20px',
                borderRadius: '10px',
                maxWidth: '760px',
            }}
        >
            <Formik enableReinitialize initialValues={initialValues} validate={validateValues} onSubmit={onSubmitForm}>
                {({ values, errors, handleChange, handleBlur, touched, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <UploadAvatar
                            name="avatar"
                            id="account-avatar"
                            img={getAvatar ?? ''}
                            helperText="Change"
                            onChange={(event) => {
                                const target = event.target as HTMLInputElement;
                                const file: File = (target.files as FileList)[0];

                                setFieldValue('file', file);
                            }}
                        />

                        <Grid container spacing={3} maxWidth="744px" marginBottom="24px">
                            <Grid item xs={12}>
                                <Input
                                    name="firstName"
                                    onChange={handleChange}
                                    id="account-first-name"
                                    value={values.firstName ?? ''}
                                    label="First name *"
                                    error={errors.firstName}
                                    touched={touched.firstName}
                                    onBlur={handleBlur}
                                    className={styles.input}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Input
                                    name="lastName"
                                    onChange={handleChange}
                                    id="account-last-name"
                                    value={values.lastName ?? ''}
                                    label="Last name *"
                                    error={errors.lastName}
                                    touched={touched.lastName}
                                    onBlur={handleBlur}
                                    className={styles.input}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Selector
                                    name="gender"
                                    onChange={handleChange}
                                    value={values.gender}
                                    id="account-gender"
                                    label="Gender"
                                    className={styles.input}
                                    options={[
                                        { value: 'Male', text: 'Male' },
                                        { value: 'Female', text: 'Female' },
                                        { value: 'Other', text: 'Other' },
                                    ]}
                                />
                            </Grid>

                            {/*<Grid item xs={12}>
                                <Selector
                                    name="language"
                                    onChange={handleChange}
                                    value={values.language}
                                    id="account-language"
                                    label="language"
                                    className={styles.input}
                                    disabled={true}
                                    options={[{ value: 'en', text: 'English' }]}
                                />
                            </Grid>*/}

                            <Grid item xs={12}>
                                <Input
                                    name="email"
                                    onChange={handleChange}
                                    id="account-email"
                                    value={values.email ?? ''}
                                    label="email address * "
                                    error={errors.email}
                                    touched={touched.email}
                                    onBlur={handleBlur}
                                    className={styles.input}
                                />
                                <Typography variant="caption" className={styles.emailTextHelper}>
                                    Used to recover your account and get notifications related to OnlyOnce.
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <PhoneNumberInput
                                    defaultValue={values.phone ?? ''}
                                    disabled={true}
                                    label="Phone Number *"
                                />
                            </Grid>
                        </Grid>
                        <Button type="submit" variant="contained" className={ui.buttonOutlinedPrimary}>
                            Save Changes
                        </Button>
                    </form>
                )}
            </Formik>
        </Box>
    );
});

export default MyAccount;
