import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Button } from '@mui/material';
import { useFormik } from 'formik';
import { TextRadio } from '@components/TextRadio/TextRadio';
import { Popup } from '@components/Popup/Popup';
import { useDataStore } from '@hooks/useDataStore';
import ui from '@theme/common/ui.module.scss';
import styles from './ServicesPopup.module.scss';

interface IFormValues {
    service: string;
}

export const ServicesPopup = observer(() => {
    const { addService } = useDataStore();

    const formik = useFormik({
        initialValues: {
            service: '',
        },
        onSubmit: (values: IFormValues) => {
            if (values.service === 'zoho') {
                addService.toggleServicePopup(false);
                addService.toggleZohoServicePopup(true);
                addService.setServiceType('zohoCrm');
                formik.resetForm();
            }

            if (values.service === 'zohoRecruit') {
                addService.toggleServicePopup(false);
                addService.toggleZohoServicePopup(true);
                addService.setServiceType('zohoRecruit');
                formik.resetForm();
            }

            if (values.service === 'email') {
                addService.toggleServicePopup(false);
                addService.toggleEmailServicePopup(true);
                formik.resetForm();
            }
        },
    });

    const handleClose = useCallback(() => {
        addService.toggleServicePopup(false);
        formik.resetForm();
    }, []);

    const notFoundServiceHandler = () => {
        addService.toggleServicePopup(false);
        addService.toggleRequestServicePopup(true);
        formik.resetForm();
    };

    const PopupFooter = () => (
        <Box className={ui.popupFooter}>
            <Typography variant="body2" className={ui.helperText}>
                step 1 of 3
            </Typography>
            <Box className={ui.buttonGroup}>
                <Button
                    type="submit"
                    form="ServicesForm"
                    variant="contained"
                    disabled={!(formik.isValid && formik.dirty)}
                    className={ui.buttonContained}
                    sx={{
                        '&.MuiButtonBase-root.Mui-disabled': {
                            backgroundColor: 'var(--c-grey-normal)',
                            color: 'var(--c-grey-dark)',
                        },
                    }}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );

    return (
        <Popup
            state={addService.isServicePopupShown}
            onClose={handleClose}
            title="Add service"
            renderFooter={<PopupFooter />}
        >
            <form id="ServicesForm" onSubmit={formik.handleSubmit}>
                <TextRadio
                    name="service"
                    label="Service"
                    onChange={formik.handleChange}
                    value={formik.values.service}
                    options={[
                        { id: 'zoho', value: 'zoho', text: 'ZOHO CRM' },
                        { id: 'zohoRecruit', value: 'zohoRecruit', text: 'ZOHO Recruit' },
                        { id: 'email', value: 'email', text: 'Email' },
                    ]}
                />
                <Button className={styles.simpleLink} disableRipple={true} onClick={notFoundServiceHandler}>
                    Didn’t find your service?
                </Button>
            </form>
        </Popup>
    );
});
