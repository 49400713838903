import { TableCell, TableHead, TableRow } from '@mui/material';
import ui from '@theme/common/ui.module.scss';

export const ServiceTableHead = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell className={ui.tableCellHead}>Organisation</TableCell>
                <TableCell className={ui.tableCellHead}>Application</TableCell>
                <TableCell className={ui.tableCellHead}>Sync Frequency</TableCell>
                <TableCell className={ui.tableCellHead}>Status</TableCell>
                <TableCell className={ui.tableCellHead}>Actions</TableCell>
            </TableRow>
        </TableHead>
    );
};
