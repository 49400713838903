import { makeAutoObservable } from 'mobx';
import { delay } from '@utils/delay';

class SyncLogsStore {
    isPopupOpened: boolean = false;
    isDetailLoading: boolean = false;
    isContentLoading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    togglePopup(state: boolean) {
        if (state) {
            this.isDetailLoading = true;
        }

        this.isPopupOpened = state;

        delay(1000).then(() => {
            this.isDetailLoading = false;
        });
    }

    async sendFilter() {
        this.isContentLoading = true;

        delay(1000).then(() => {
            this.isContentLoading = false;
        });
    }
}

export default SyncLogsStore;
