import { Box } from '@mui/material';

import { Header } from './components/Header/Header';
import { Title } from './components/Title/Title';
import { Form } from './components/Form/Form';
import { Footer } from './components/Footer/Footer';

import styles from './Onboarding.module.scss';
import { Progress } from './components/Progress/Progress';
import FormProvider from './context/FormProvider';

export const Onboarding = () => {
    return (
        <FormProvider>
            <Box className={styles.wrapper}>
                <Header />
                <Box className={styles.content}>
                    <Box className={styles.container}>
                        <Title />
                        <Progress />
                        <Form />
                    </Box>
                </Box>
                <Footer />
            </Box>
        </FormProvider>
    );
};
