import { makeAutoObservable } from 'mobx';
import OOConnect, { UpdateCompanyData } from '@services/OOConnect';

export interface ICompaniesData {
    chamberOfCommerceNr: string | null;
    createdAt: string;
    employees: number;
    id: string;
    legalType: string | null;
    logo: Blob | File | null;
    name: string;
    receptionPhone: string | null;
    slogan: string | null;
    userId: string;
    website: string | null;
}

class CompaniesStore {
    isLoadingCompanies = false;
    hasCompanies = false;

    isDeleteCompanyPopupShown = false;
    companyIdToDelete: ICompaniesData['id'] | null = null;
    companyNameToDelete: ICompaniesData['name'] | null = null;

    isEditCompanyPopupShown = false;
    companyToEdit: Partial<ICompaniesData> | null = null;

    companies: ICompaniesData[] = null!;

    constructor() {
        makeAutoObservable(this);
    }

    setIsDeleteCompanyPopupShown(state: boolean) {
        this.isDeleteCompanyPopupShown = state;
    }

    setCompanyToDelete({ id, name }: { id: ICompaniesData['id']; name: ICompaniesData['name'] }) {
        this.companyIdToDelete = id;
        this.companyNameToDelete = name;
    }

    setIsEditCompanyPopupShown(state: boolean) {
        this.isEditCompanyPopupShown = state;
    }

    setCompanyToEdit({ id }: { id: ICompaniesData['id'] }) {
        const companyToEdit = this.companies.find((company) => company.id === id);

        if (companyToEdit) {
            this.companyToEdit = companyToEdit;
        }
    }

    async getMyCompanies() {
        this.isLoadingCompanies = true;

        try {
            const response = await OOConnect.getCompanies();
            if (response.data.items && response.data.items.length > 0) {
                this.companies = response.data.items;
                this.hasCompanies = true;
            } else {
                this.hasCompanies = false;
            }

            return response.data.items as ICompaniesData;
        } finally {
            this.isLoadingCompanies = false;
        }
    }

    async saveCompany({ name, slogan }: { name: ICompaniesData['name']; slogan?: ICompaniesData['slogan'] }) {
        const response = await OOConnect.saveCompany({
            name,
            slogan: slogan ?? '',
        });

        return response.data as ICompaniesData['id'];
    }

    async updateCompany({ id, data }: { id: ICompaniesData['id']; data: UpdateCompanyData }) {
        const response = await OOConnect.updateCompany({
            id,
            data,
        });

        return response.data as ICompaniesData['id'];
    }

    async uploadLogo({ id, formData }: { id: ICompaniesData['id']; formData: FormData }) {
        return OOConnect.updateCompanyLogo({ id, formData });
    }

    async deleteCompany({ id }: { id: ICompaniesData['id'] }) {
        return OOConnect.deleteCompany({ id });
    }
}

export default CompaniesStore;
