class Config {
    readonly OO_API = process.env.REACT_APP_OO_API;
    readonly OOC_API = process.env.REACT_APP_OOC_API;
    readonly GEOLOCATION_API = process.env.REACT_APP_GEOLOCATION_API;
    readonly AWS_REGION = process.env.REACT_APP_AWS_REGION;
    readonly AWS_COGNITO_POOL_ID = process.env.REACT_APP_AWS_COGNITO_POOL_ID;
    readonly AWS_COGNITO_CLIENT_ID = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID;
    readonly DOMAIN_NAME = process.env.REACT_APP_DOMAIN_NAME;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Config();
