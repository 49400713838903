import { useFormContext } from '@pages/Onboarding/context/FormContext';
import styles from './Progress.module.scss';
import { Step } from './Step';

export const Progress = () => {
    const { forms } = useFormContext();

    return (
        <div className={styles.wrapper}>
            {forms.map(({ title }, index) => (
                <Step title={title} step={index} key={title.replaceAll(' ', '_').toUpperCase()} />
            ))}
        </div>
    );
};
