import { Form, Formik } from 'formik';
import { Input } from '@components/Input/Input';
import { FormNavigation } from '../../FormNavigation/FormNavigation';
import { useFormContext } from '@pages/Onboarding/context/FormContext';
import { UploadAvatar } from '@components/UploadAvatar/UploadAvatar';
import { AdditionalFormInfo } from '../../AdditionalFormInfo/AdditionalFormInfo';
import { Grid } from '@mui/material';
import { AutocompleteTextField } from '@components/AutocompleteTextField/AutocompleteTextField';
import { useCallback, useEffect, useState } from 'react';
import { useDataStore } from '@hooks/useDataStore';
import { debounce } from 'throttle-debounce';
import OOConnect from '@services/OOConnect';
import { Popup } from '@components/Popup/Popup';
import { LoadingButton } from '@mui/lab';
import createCompanySchema, { CreateCompanyFormValues } from '@schema/company/companySchema';

type CompanyResponse = {
    chamberOfCommerceNr: string | null;
    createdAt: string;
    employees: number;
    id: string;
    legalType: string | null;
    logo: string | null;
    name: string;
    receptionPhone: string | null;
    slogan: string | null;
    userId: string;
    website: string | null;
};

export const CompanyInfoForm = () => {
    const { addService, notification } = useDataStore();
    const { setStep, formValues, setFormValues } = useFormContext();

    const [companies, setCompanies] = useState<CompanyResponse[] | null>(null);
    const [isCreateCompanyPopupShown, setIsCreateCompanyPopupShown] = useState(false);
    const [newCompanyName, setNewCompanyName] = useState('');
    const [isLoadingCreate, setIsLoadingCreate] = useState(false);
    const [defaultCompany, setDefaultCompany] = useState({
        value: '',
        text: '',
    });

    const initialValues: CreateCompanyFormValues = {
        logo: formValues.logo,
        companyName: formValues.companyName,
        companyId: formValues.companyId,
        headline: formValues.headline,
    };

    const formatCompaniesOptions = useCallback(() => {
        return companies
            ? companies.map((company) => ({
                  value: company.id,
                  text: company.name,
              }))
            : [];
    }, [companies]);

    const handleCompanySearch = debounce(250, async (value: string) => {
        await addService.getCompanies(value).then((companies) => {
            setCompanies(companies);
        });
    });

    const handleEmployerCreation = (value: string) => {
        if (!value.length || value.length > 50 || value.length < 2) return;

        setNewCompanyName(value);
        setIsCreateCompanyPopupShown(true);
    };

    const cancelCompanyCreation = () => {
        setIsCreateCompanyPopupShown(false);
        setNewCompanyName('');
    };

    const handleCreateCompany = (setFieldValue: (field: string, value: any) => void) => {
        setIsLoadingCreate(true);

        const confirmCompanyCreation = async () => {
            try {
                const { data } = await OOConnect.saveCompany({
                    name: newCompanyName,
                });

                setFieldValue('companyId', data);
                setFieldValue('companyName', newCompanyName);
                setFieldValue('logo', undefined);
                setFieldValue('headline', '');

                setFormValues((prev) => ({
                    ...prev,
                    companyId: data,
                    companyName: newCompanyName,
                    logo: undefined,
                    headline: '',
                }));

                notification.sendNotification({ message: 'Your company has been succesfully created.' });
            } catch {
                notification.sendNotification({
                    type: 'error',
                    message: 'There was an error while trying to create this company.',
                });
            } finally {
                setIsLoadingCreate(false);
                setIsCreateCompanyPopupShown(false);
            }
        };

        confirmCompanyCreation();
    };

    const submitForm = async (values: CreateCompanyFormValues) => {
        setFormValues((prev) => ({
            ...prev,
            ...values,
        }));

        setIsLoadingCreate(true);

        const formData = new FormData();
        formData.append('logo', values.logo as Blob);
        formData.append('id', values.companyId);

        Promise.all([
            OOConnect.updateCompany({
                id: values.companyId!,
                data: {
                    slogan: values.headline,
                    name: values.companyName as string,
                },
            }),
            OOConnect.updateCompanyLogo({ id: values.companyId!, formData }),
        ])
            .then(() => {
                setStep((prev) => prev + 1);
            })
            .catch(() => {
                return notification.sendNotification({
                    type: 'error',
                    message: 'There was an error while trying to save company data. Please try again.',
                });
            })
            .finally(() => {
                setIsLoadingCreate(false);
            });
    };

    useEffect(() => {
        const id = formValues.companyId;

        if (id) {
            OOConnect.getCompanyById(id).then((response) => {
                const company = response.data;
                setDefaultCompany({
                    text: company.name,
                    value: company.id,
                });
            });
        } else {
            handleCompanySearch('');
        }
    }, [formValues.companyId]); // eslint-disable-line

    return (
        <Formik initialValues={initialValues} validationSchema={createCompanySchema} onSubmit={submitForm}>
            {({ values, handleChange, handleBlur, setFieldValue, isValid }) => (
                <Form>
                    <Grid item marginBottom="30px">
                        <AutocompleteTextField
                            name="companyId"
                            id="onboard-company"
                            setFieldValue={setFieldValue}
                            options={formatCompaniesOptions}
                            defaultOption={defaultCompany}
                            label="Company name *"
                            onChangeSearchCompany={handleCompanySearch}
                            onChangeEmployerCreation={handleEmployerCreation}
                        />
                    </Grid>

                    <input hidden name="companyName" />

                    <UploadAvatar
                        name="logo"
                        id="onboard-logo"
                        label="Company logo *"
                        img={formValues.logo}
                        onChange={(e) => {
                            const target = e.target as HTMLInputElement;
                            const file = (target.files as FileList)[0];
                            setFieldValue('logo', file);
                        }}
                    />

                    <Input
                        name="headline"
                        onChange={handleChange}
                        id="onboard-headline"
                        value={values.headline}
                        label="Company headline (will be shown in the app) *"
                        onBlur={handleBlur}
                    />

                    <AdditionalFormInfo />

                    <FormNavigation disabled={!values.companyId || !values.logo || !values.headline || !isValid} />

                    <Popup
                        title={`Create "${newCompanyName}" company?`}
                        state={isCreateCompanyPopupShown}
                        onClose={cancelCompanyCreation}
                        renderFooter={
                            <LoadingButton loading={isLoadingCreate} onClick={() => handleCreateCompany(setFieldValue)}>
                                Create
                            </LoadingButton>
                        }
                    >
                        <h4>Do you really want to create? Are you sure?</h4>
                    </Popup>
                </Form>
            )}
        </Formik>
    );
};
