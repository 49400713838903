import { FC, ReactElement } from 'react';
import { Skeleton } from '@mui/material';

type TVariant = 'rectangular' | 'text' | 'rounded' | 'circular' | undefined;

interface ISkeletonCustomProps {
    isLoading: boolean;
    children: ReactElement;
    width: string | number;
    height: string | number;
    borderRadius?: string;
    variant?: TVariant;
    marginBottom?: string;
}

export const SkeletonCustom: FC<ISkeletonCustomProps> = ({
    isLoading,
    children,
    width,
    height,
    marginBottom = '',
    borderRadius = '10px',
    variant = 'rectangular',
}) => {
    if (isLoading)
        return (
            <Skeleton
                sx={{
                    width,
                    height,
                    borderRadius,
                    marginBottom,
                    backgroundColor: '#EDEDED',
                }}
                animation="wave"
                variant={variant}
            />
        );

    return children;
};
