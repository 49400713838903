import { observer } from 'mobx-react-lite';
import { ReactComponent as Logotype } from '@assets/icons/logotype.svg';
import styles from './Header.module.scss';

export const Header = observer(() => {
    return (
        <header className={styles.header}>
            <Logotype className={styles.logotype} />
        </header>
    );
});
