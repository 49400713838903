import { FC, ReactElement, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ListRoutes } from '@components/AppRouter/routes';
import { Auth } from 'aws-amplify';
import OOApi from '@services/OOApi';

interface IAuthorization {
    children: ReactElement;
}

// TODO: Implement browser back button handler
export const Authorization: FC<IAuthorization> = observer(({ children }) => {
    const navigation = useNavigate();
    const { pathname } = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    const authRedirectLogic = async () => {
        setIsLoading(true);

        try {
            const user = await Auth.currentAuthenticatedUser();
            const account = await OOApi.getAccount();
            const isOnboarded = !!user.attributes['email'] || !!account.data.email;

            if (!isOnboarded) {
                return navigation(ListRoutes.Onboarding);
            }

            if (ListRoutes.Login === pathname || ListRoutes.Onboarding === pathname) {
                navigation(ListRoutes.SyncService);
            }
        } catch (error) {
            navigation(ListRoutes.Login);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        authRedirectLogic();
    }, [pathname]);

    if (isLoading) {
        // @todo - implement loading spinner instead of displaying blank screen
        return null;
    }

    return children;
});
