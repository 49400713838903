import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Skeleton, Typography } from '@mui/material';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { useDataStore } from '@hooks/useDataStore';
import { Errors } from '@constants/errors';
import styles from './PhoneNumberInput.module.scss';

interface IPhoneNumberInputProps {
    label: string;
    error?: boolean;
    defaultValue?: string;
    disabled?: boolean;
    className?: string;
    onCatchError?: (state: boolean) => void;
    onChangePhoneNumber?: (value: string) => void;
}

export const PhoneNumberInput: FC<IPhoneNumberInputProps> = observer(
    ({ label, onCatchError, onChangePhoneNumber, defaultValue = '', className, disabled = false }) => {
        const { geolocation } = useDataStore();
        const [phoneNumber, setPhoneNumber] = useState(defaultValue);
        const [typedOnce, setTypedOnce] = useState<boolean>(false);
        const [failedPhoneNumber, setFailedPhoneNumber] = useState<boolean>(false);

        const onChangePhoneNumberHandler = (value: string) => {
            if (disabled) return;

            const isValidPhoneNumber = matchIsValidTel(value);

            setTypedOnce(true);
            setPhoneNumber(value);
            setFailedPhoneNumber(isValidPhoneNumber);
        };

        useEffect(() => {
            onCatchError && onCatchError(failedPhoneNumber);
        }, [failedPhoneNumber, onCatchError]);

        useEffect(() => {
            onChangePhoneNumber && onChangePhoneNumber(phoneNumber);
        }, [phoneNumber, onChangePhoneNumber]);

        useEffect(() => {
            setPhoneNumber(defaultValue);
        }, [defaultValue]);

        return (
            <Box marginBottom="24px" className={className}>
                <label className={styles.phoneNumberLabel} htmlFor="phone-number">
                    {label}
                </label>
                {geolocation.isLoading && !defaultValue ? (
                    <Skeleton
                        variant="rounded"
                        width={360}
                        height={48}
                        sx={{
                            borderRadius: '12px',
                        }}
                    />
                ) : (
                    <MuiTelInput
                        id="phone-number"
                        value={phoneNumber}
                        fullWidth
                        defaultCountry={(!defaultValue && geolocation.countryCode) || undefined}
                        onChange={onChangePhoneNumberHandler}
                        error={!failedPhoneNumber && typedOnce}
                        autoComplete="off"
                        disabled={disabled}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '48px',
                                paddingLeft: '9px',
                                borderRadius: '12px',
                                backgroundColor: 'var(--c-grey-light)',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--c-grey-normal)',
                            },
                            '& .Mui-error': {
                                backgroundColor: '#FCEAE6',
                            },
                            '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'transparent',
                            },
                            '& .MuiTelInput-Flag picture img': {
                                height: '16px',
                                borderRadius: '3px',
                            },
                        }}
                    />
                )}
                {!failedPhoneNumber && typedOnce && (
                    <Typography variant="caption" className={styles.phoneNumberError}>
                        {Errors.PhoneNumber}
                    </Typography>
                )}
            </Box>
        );
    },
);
