import * as yup from 'yup';

const licenseNumberSchema = yup
    .object()
    .shape({
        licenseNumber: yup
            .string()
            .matches(
                /^OO-OOC-VKJPG-3V66T$/,
                'The code you have entered is not valid. Please, enter new code and try again, or contact Only Once.',
            ),
        accept: yup.boolean().required('To continue, you should first accept our terms and conditions.'),
    })
    .defined();

export type LicenseNumberFormValues = yup.InferType<typeof licenseNumberSchema>;

export default licenseNumberSchema;
