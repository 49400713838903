import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Button } from '@mui/material';
import { useFormik } from 'formik';
import { Popup } from '@components/Popup/Popup';
import { Input } from '@components/Input/Input';
import { Selector } from '@components/Selector/Selector';
import { Textarea } from '@components/Textarea/Textarea';
import { MultipleFields } from '@components/MultipleFields/MultipleFields';
import { IValues } from '@interfaces/IMultipleFields';
import { useDataStore } from '@hooks/useDataStore';
import { validation } from '@utils/validation';
import { Errors } from '@constants/errors';
import { faultProvider } from '@utils/faultProvider';
import ui from '@theme/common/ui.module.scss';
import styles from './EmailServicePopup.module.scss';

interface IEmailServiceValues {
    company: string;
    email: string;
    syncType: string;
    description: string;
    extraEmails: IValues;
}

interface IErrors {
    company?: string;
    email?: string;
    syncType?: string;
    description?: string;
    extraEmails?: Record<string, string>;
}

export const EmailServicePopup = observer(() => {
    const { addService } = useDataStore();
    const { validateEmail, validateLength } = validation();
    const { getLimitationFault } = faultProvider();

    const validateForm = (values: IEmailServiceValues) => {
        const errors: IErrors = {};
        const extraEmails = values.extraEmails;

        if (!validateEmail(values.email)) {
            errors.email = Errors.RequiredField;
        }

        if (!validateLength(values.description, 100)) {
            errors.description = getLimitationFault('description', 100);
        }

        Object.keys(extraEmails).forEach((key) => {
            if (!validateEmail(extraEmails[key].value)) {
                errors.extraEmails = {
                    ...errors.extraEmails,
                    [key]: Errors.RequiredField,
                };
            }
        });

        return errors;
    };

    const onSubmitForm = (values: IEmailServiceValues) => {
        console.log('EmailServicePopup submitting', values);
    };

    const formik = useFormik({
        initialValues: {
            company: '',
            email: '',
            syncType: '',
            description: '',
            extraEmails: {},
        },
        onSubmit: onSubmitForm,
        validate: validateForm,
    });

    const handleBack = () => {
        addService.toggleEmailServicePopup(false);
        addService.toggleServicePopup(true);
        formik.resetForm();
    };

    const handleClose = useCallback(() => {
        addService.toggleEmailServicePopup(false);
        formik.resetForm();
    }, []);

    const PopupFooter = () => (
        <Box className={ui.popupFooter}>
            <Typography variant="body2" className={ui.helperText}>
                step 2 of 3
            </Typography>
            <Box className={ui.buttonGroup}>
                <Button variant="outlined" className={ui.buttonOutlined} onClick={handleBack}>
                    Back
                </Button>
                <Button
                    type="submit"
                    form="EmailServiceForm"
                    variant="contained"
                    disabled={!(formik.isValid && formik.dirty)}
                    className={ui.buttonContained}
                    sx={{
                        '&.MuiButtonBase-root.Mui-disabled': {
                            backgroundColor: 'var(--c-grey-normal)',
                            color: 'var(--c-grey-dark)',
                        },
                    }}
                >
                    Add
                </Button>
            </Box>
        </Box>
    );

    return (
        <Popup
            state={addService.isEmailServicePopupShown}
            onClose={handleClose}
            title="Add Email Service"
            renderFooter={<PopupFooter />}
        >
            <form id="EmailServiceForm" onSubmit={formik.handleSubmit}>
                <Selector
                    name="company"
                    onChange={formik.handleChange}
                    value={formik.values.company}
                    id="email-service-company"
                    label="Company"
                    className={styles.input}
                    options={[
                        { value: 'company_1', text: 'Company 1' },
                        { value: 'company_2', text: 'Company 2' },
                        { value: 'company_3', text: 'Company 3' },
                    ]}
                />
                <Selector
                    name="syncType"
                    onChange={formik.handleChange}
                    value={formik.values.syncType}
                    id="email-service-sync-type"
                    label="Sync type"
                    className={styles.input}
                    options={[
                        { value: 'company_1', text: 'Company 1' },
                        { value: 'company_2', text: 'Company 2' },
                        { value: 'company_3', text: 'Company 3' },
                    ]}
                />
                <Textarea
                    name="description"
                    value={formik.values.description}
                    id="email-service-description"
                    label="Description"
                    maxLength={100}
                    className={styles.input}
                    setFieldValue={formik.setFieldValue}
                />
                <Input
                    type="text"
                    name="email"
                    onChange={formik.handleChange}
                    id="email-service-email"
                    value={formik.values.email}
                    label="Main Email Address"
                    error={formik.errors.email}
                    touched={formik.touched.email}
                    onBlur={formik.handleBlur}
                    className={styles.input}
                />
                <MultipleFields
                    name="extraEmails"
                    setFieldValue={formik.setFieldValue}
                    errors={formik.errors.extraEmails}
                    initialNumeration={2}
                    startLabel="Email Address"
                />
            </form>
        </Popup>
    );
});
