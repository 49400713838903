import { FC, memo, useCallback, useEffect, useState } from 'react';
import { FormikErrors } from 'formik';
import { Box, Button } from '@mui/material';
import { FieldItem } from './FieldItem';
import { IFields, IValues } from '@interfaces/IMultipleFields';
import { ReactComponent as EmailPlusIcon } from '@assets/icons/email-plus.svg';
import styles from './MultipleFields.module.scss';

interface IMultipleFields {
    name: string;
    errors: FormikErrors<IValues> | undefined;
    startLabel: string;
    initialNumeration: number;
    setFieldValue: (key: string, value: IValues) => void;
}

export const MultipleFields: FC<IMultipleFields> = memo(
    ({ name, errors, startLabel, setFieldValue, initialNumeration }) => {
        const [values, setValues] = useState<IValues>({});
        const [fields, setFields] = useState<IFields[]>([]);
        const [signalRemoving, setSignalRemoving] = useState(false);

        const removeField = useCallback((key: number, uniqueKey: string) => {
            setFields((prevState) => prevState.filter((_, index) => index !== key));
            setValues((prevState: IValues) => {
                delete prevState[uniqueKey];
                return { ...prevState };
            });
            setSignalRemoving(true);
        }, []);

        const onSetFieldValueHandler = useCallback(() => {
            setFieldValue(name, values);
        }, [setFieldValue, values]);

        const addEmailAddress = () => {
            setFields((prevState) => {
                const fieldLength = prevState.length;
                const newUniqueID = fieldLength && prevState[fieldLength - 1].uniqueID + 1;
                const uniqueID = fieldLength ? newUniqueID : initialNumeration;

                return [
                    ...prevState,
                    {
                        uniqueID,
                        uniqueKey: `input_${uniqueID}`,
                        setValues,
                        removeField,
                    },
                ];
            });
        };

        useEffect(() => {
            if (!signalRemoving) return;

            setFieldValue(name, values);
            setSignalRemoving(false);
        }, [signalRemoving]);

        return (
            <Box className={styles.wrapper}>
                {fields?.map((field, index) => {
                    const error = errors && errors[field.uniqueKey] ? `${errors[field.uniqueKey]}` : '';

                    return (
                        <FieldItem
                            {...field}
                            key={field.uniqueKey}
                            label={`${startLabel} ${index + initialNumeration}`}
                            onSetFieldValueHandler={onSetFieldValueHandler}
                            fieldKey={index}
                            error={error}
                        />
                    );
                })}
                <Button
                    fullWidth
                    className={styles.button}
                    variant="outlined"
                    startIcon={<EmailPlusIcon className={styles.icon} />}
                    onClick={addEmailAddress}
                    sx={{
                        '& .MuiButton-startIcon': {
                            marginLeft: '0',
                        },
                    }}
                >
                    Add Email
                </Button>
            </Box>
        );
    },
);
