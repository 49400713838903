import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { auth } from '@config/auth';
import { Authorization } from '@components/Authorization/Authorization';
import { AppRouter } from '@components/AppRouter/AppRouter';
import { Notification } from '@components/Notification/Notification';
import { theme } from '@theme/theme';
import '@theme/global/index.scss';

export const App = () => {
    Amplify.configure({ Auth: { ...auth } });

    return (
        <BrowserRouter>
            <Authorization>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <AppRouter />
                        <Notification />
                    </ThemeProvider>
                </StyledEngineProvider>
            </Authorization>
        </BrowserRouter>
    );
};
