import { makeAutoObservable } from 'mobx';
import { ILoginConfirmInputData } from '@services/OOApi';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { parsedPhone } from '@utils/phone';
import { v4 as uuidv4 } from 'uuid';

interface ISessionUserData {
    userId: string;
    sessionId: string;
}

export interface ICognitoError {
    code: string;
    name: string;
    message: string;
}

class AuthorizationStore {
    private sessionUserData: ISessionUserData = null!;

    phoneNumber: string = null!;

    constructor() {
        makeAutoObservable(this);
    }

    async loginWithPhoneNumber(phoneNumber: string) {
        try {
            let parsedPhoneNumber = parsedPhone(phoneNumber);
            return (await Auth.signIn(parsedPhoneNumber)) as CognitoUser;
        } catch (e: any) {
            throw e;
        }
    }

    async signUpWithPhoneNumber(phoneNumber: string) {
        try {
            const { user } = await Auth.signUp({
                username: parsedPhone(phoneNumber),
                password: 'OO-' + uuidv4(),
            });

            return user;
        } catch (e: any) {
            throw new Error(e);
        }
    }

    async verifyCode({ cognitoUser, code }: ILoginConfirmInputData) {
        try {
            const data: Record<string, string> = {};

            if (this.sessionUserData) {
                data.userId = this.sessionUserData.userId;
                data.sessionId = this.sessionUserData.sessionId;
            }

            await Auth.sendCustomChallengeAnswer(cognitoUser, code);
            return (await Auth.currentSession()).getIdToken().getJwtToken();
        } catch (error: any) {
            throw error;
        }
    }

    async signOut() {
        await Auth.signOut();
    }
}

export default AuthorizationStore;
