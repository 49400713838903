import { ChangeEvent, FC, memo, useMemo, useState } from 'react';
import { Box, InputLabel, OutlinedInput, TextField, InputAdornment, IconButton, FormHelperText } from '@mui/material';
import { themeProvider } from './theme.styled';
import { ReactComponent as PasswordIcon } from '@assets/icons/password-eye.svg';
import { ReactComponent as CrossedOutPasswordIcon } from '@assets/icons/crossed-out-password-eye.svg';
import { ReactComponent as SearchIcon } from '@assets/icons/search.svg';
import ui from '@theme/common/ui.module.scss';
import styles from './Input.module.scss';
import { ErrorMessage } from '@components/ErrorMessage/ErrorMessage';

type TSize = 'large' | 'small';

export interface IInputProps {
    name: string;
    value?: string;
    touched?: boolean | undefined;
    onChange?: (e: ChangeEvent<any>) => void;
    onBlur?: (e: ChangeEvent<any>) => void;
    type?: string;
    id?: string;
    error?: string;
    label?: string;
    search?: boolean;
    placeholder?: string;
    size?: TSize;
    className?: string;
    autocomplete?: boolean;
    helperText?: string;
}

export const Input: FC<IInputProps> = memo(
    ({
        name,
        type = 'text',
        id,
        onChange,
        onBlur,
        value = '',
        error,
        touched,
        label,
        className,
        placeholder = '',
        search = false,
        size = 'large',
        autocomplete = false,
        helperText,
        ...props
    }) => {
        const [passwordShown, setPasswordShown] = useState(false);

        const theme = useMemo(() => {
            return themeProvider[size];
        }, [size]);

        const togglePasswordShown = () => {
            setPasswordShown((prevState) => {
                return !prevState;
            });
        };

        const InputComponent = autocomplete ? TextField : OutlinedInput;

        return (
            <Box marginBottom="24px" className={className}>
                {label && (
                    <InputLabel htmlFor={id} className={ui.inputLabel}>
                        {label}
                    </InputLabel>
                )}
                <InputComponent
                    {...props}
                    fullWidth
                    name={name}
                    type={passwordShown ? 'text' : type}
                    id={id}
                    onChange={onChange}
                    value={value}
                    error={!!error && touched}
                    onBlur={onBlur}
                    autoComplete="off"
                    {...(!autocomplete && {
                        startAdornment: search && (
                            <InputAdornment position="end">
                                <SearchIcon className={styles.searchIcon} />
                            </InputAdornment>
                        ),
                    })}
                    {...(!autocomplete && {
                        endAdornment: type === 'password' && (
                            <InputAdornment position="end">
                                <IconButton onClick={togglePasswordShown} className={styles.passwordIcon}>
                                    {passwordShown ? <CrossedOutPasswordIcon /> : <PasswordIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    })}
                    placeholder={placeholder}
                    sx={theme}
                />
                {error && touched && <ErrorMessage>{error}</ErrorMessage>}

                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </Box>
        );
    },
);
