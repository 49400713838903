import { Typography } from '@mui/material';
import styles from './AdditionalFormInfo.module.scss';

export const AdditionalFormInfo = () => {
    return (
        <Typography className={styles.info} variant="caption">
            Fields with (*) are required.
        </Typography>
    );
};
