import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Typography } from '@mui/material';
import { useDataStore } from '@hooks/useDataStore';
import { Popup } from '@components/Popup/Popup';
import ui from '@theme/common/ui.module.scss';
import styles from './ArchivePopup.module.scss';

export const ArchivePopup = observer(() => {
    const { syncServices } = useDataStore();

    const onSubmitForm = () => {
        console.log('Archive popup submitting');
    };

    const handleClose = useCallback(() => {
        syncServices.toggleArchivePopup(false);
    }, []);

    const PopupFooter = () => (
        <Box className={styles.footerContainer}>
            <Button variant="outlined" onClick={handleClose} className={ui.buttonOutlined}>
                Cancel
            </Button>
            <Button variant="contained" color="error" className={styles.archiveButton} onClick={onSubmitForm}>
                Archive
            </Button>
        </Box>
    );

    return (
        <Popup
            state={syncServices.isArchivePopupShown}
            onClose={handleClose}
            availableCloseButton={false}
            title="Archive service?"
            renderFooter={<PopupFooter />}
        >
            <Typography variant="body1">
                Are you sure you want to archive Synced Service? You won’t be able to activate it again.
            </Typography>
        </Popup>
    );
});
