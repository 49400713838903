import { Form, Formik } from 'formik';
import { Input } from '@components/Input/Input';
import { Switcher } from '@components/Switcher/Switcher';
import licenseNumberSchema, { LicenseNumberFormValues } from './schema';
import { FormNavigation } from '../../FormNavigation/FormNavigation';
import { useFormContext } from '@pages/Onboarding/context/FormContext';
import { AdditionalFormInfo } from '../../AdditionalFormInfo/AdditionalFormInfo';

export const LicenseNumberForm = () => {
    const { setStep, formValues, setFormValues } = useFormContext();

    const initialValues: LicenseNumberFormValues = {
        licenseNumber: formValues.licenseNumber,
        accept: formValues.accept,
    };

    const submitForm = (values: LicenseNumberFormValues) => {
        setFormValues((prev) => ({
            ...prev,
            ...values,
        }));
        setStep((prev) => prev + 1);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={licenseNumberSchema}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
            onSubmit={submitForm}
        >
            {({ values, errors, handleChange, handleBlur, touched, setFieldValue, setFieldError }) => (
                <Form>
                    <Input
                        name="licenseNumber"
                        onChange={(e) => {
                            setFieldError('licenseNumber', '');
                            setFieldValue('licenseNumber', e.target.value.trim().toUpperCase());
                        }}
                        id="licenseNumber"
                        value={values.licenseNumber}
                        label="License key *"
                        error={errors.licenseNumber}
                        touched={touched.licenseNumber}
                        onBlur={handleBlur}
                    />

                    <Switcher
                        checked={values.accept}
                        onChange={handleChange}
                        id="onboard-accept"
                        name="accept"
                        label={{
                            text: 'Accept',
                            textLink: 'Terms and Conditions',
                            url: 'https://www.onlyonce.com/general-terms/',
                        }}
                    />

                    <AdditionalFormInfo />

                    <FormNavigation isFirstStep={true} disabled={!values.accept || !values.licenseNumber} />
                </Form>
            )}
        </Formik>
    );
};
