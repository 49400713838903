import { FC, useEffect, useState } from 'react';
import { Box, Avatar, ButtonBase, InputLabel } from '@mui/material';
import { ReactComponent as AvatarIcon } from '@assets/icons/avatar.svg';
import ui from '@theme/common/ui.module.scss';
import styles from './UploadAvatar.module.scss';
import { ErrorMessage } from '@components/ErrorMessage/ErrorMessage';

interface IUploadAvatarProps {
    onChange: (e: Event) => void;
    name: string;
    id: string;
    label?: string;
    img?: Blob | string;
    helperText?: string;
    className?: string;
    error?: string;
}

export const UploadAvatar: FC<IUploadAvatarProps> = ({
    name,
    id,
    label,
    helperText,
    img = '',
    className,
    onChange,
    error,
}) => {
    const [avatarURL, setAvatarURL] = useState<string>('');

    const onChangeAvatar = (event: any) => {
        setAvatarURL('');

        const files = event.target.files;

        onChange(event);

        if (files && files[0]) {
            const url = URL.createObjectURL(files[0]);

            setAvatarURL(url);
        }
    };

    useEffect(() => {
        if (!img) {
            setAvatarURL('');
        }

        if (img instanceof Blob) {
            const url = URL.createObjectURL(img);
            setAvatarURL(url);
        }

        if (typeof img === 'string') {
            setAvatarURL(img);
        }
    }, [img]);

    return (
        <Box marginBottom="24px" className={className}>
            {label && (
                <InputLabel htmlFor={id} className={ui.inputLabel}>
                    {label}
                </InputLabel>
            )}
            <ButtonBase component="label" className={styles.button}>
                <input
                    hidden
                    accept="image/jpeg,image/png,image/jpg"
                    type="file"
                    name={name}
                    id={id}
                    onChange={onChangeAvatar}
                />
                <Avatar src={avatarURL} alt="Avatar" className={error ? styles.error : styles.avatar}>
                    <AvatarIcon className={styles.icon} />
                </Avatar>
                {helperText && avatarURL && <span className={styles.helperText}>{helperText}</span>}
            </ButtonBase>

            {error && <ErrorMessage>{error}</ErrorMessage>}
        </Box>
    );
};
