import { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, IconButton, TableCell, TableRow } from '@mui/material';
import { useDataStore } from '@hooks/useDataStore';
import Zoho from '@assets/images/Zoho.png';
import { ReactComponent as RemoveIcon } from '@assets/icons/remove.svg';
import ui from '@theme/common/ui.module.scss';
import styles from './ServiceTable.module.scss';

interface IServiceTableRowProps {
    accountName: string;
    serviceId: string;
    serviceName: string;
    syncFrequency: any;
    status: string;
    adminName: string;
}

export const ServiceTableRow: FC<IServiceTableRowProps> = observer(
    ({ accountName, serviceName, syncFrequency, status, adminName }) => {
        const { syncServices } = useDataStore();
        const [statusState, setStatusState] = useState(true);

        const onSwitchStatus = () => {
            setStatusState((prevState) => {
                return !prevState;
            });
        };

        const archiveService = () => {
            syncServices.toggleArchivePopup(true);
        };

        return (
            <TableRow className={ui.tableRow}>
                <TableCell className={ui.tableSellItem}>{accountName}</TableCell>
                <TableCell className={ui.tableSellItem}>
                    <Box className={ui.cardAppBox}>
                        <Box component="figure" className={ui.cardAppBoxFigure}>
                            <img src={Zoho} alt="" className={ui.cardAppBoxImage} />
                        </Box>
                        <Box component="span" className={ui.cardAppBoxText}>
                            {serviceName}
                        </Box>
                    </Box>
                </TableCell>
                <TableCell className={ui.tableSellItem}>{syncFrequency}</TableCell>
                <TableCell className={ui.tableSellItem}>{status}</TableCell>
                <TableCell className={ui.tableSellItem}>
                    <Box className={styles.actions}>
                        <IconButton className={styles.actionToggleInner} onClick={onSwitchStatus}>
                            <Box
                                component="span"
                                className={`${styles.actionToggle} ${statusState ? styles.actionToggleActive : ''}`}
                            />
                        </IconButton>
                        <IconButton className={styles.actionRemove} onClick={archiveService}>
                            <RemoveIcon width="16px" fill="var(--c-secondary-red)" />
                        </IconButton>
                    </Box>
                </TableCell>
            </TableRow>
        );
    },
);
