import { useFormContext } from '@pages/Onboarding/context/FormContext';
import { useMemo } from 'react';
import styled from 'styled-components';

type StepVariablesType = {
    isCurrentStep: boolean;
    isCompletedStep: boolean;
    isUpcomingStep: boolean;
};

export const Step = ({ step, title }: { step: number; title: string }) => {
    const { step: currentStep } = useFormContext();

    const stepVariables: StepVariablesType = useMemo(
        () => ({
            isCurrentStep: step === currentStep,
            isCompletedStep: step < currentStep,
            isUpcomingStep: step > currentStep,
        }),
        [step, currentStep],
    );

    return (
        <Wrapper>
            <EmptyLine />
            <FilledLine $stepVariables={stepVariables} />
            <StepCount $stepVariables={stepVariables}>{step + 1}</StepCount>
            <Title $stepVariables={stepVariables}>{title}</Title>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
`;

const EmptyLine = styled.div`
    height: 2px;
    background: #cad0d9;
    width: 100%;
`;

const FilledLine = styled.div<{ $stepVariables: StepVariablesType }>`
    ${({ $stepVariables }) => $stepVariables.isUpcomingStep && 'display: none;'}
    position: absolute;
    left: 0;
    height: 2px;
    background: #00a0e1;
    width: ${({ $stepVariables }) => ($stepVariables.isCurrentStep ? '50%' : '100%')};
`;

const StepCount = styled.span<{ $stepVariables: StepVariablesType }>`
    position: absolute;
    width: 32px;
    height: 32px;
    background: ${({ $stepVariables }) => ($stepVariables.isUpcomingStep ? '#ffffff' : '#00a0e1')};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: calc(50% - 16px);
    color: ${({ $stepVariables }) => ($stepVariables.isUpcomingStep ? '#737F8B' : '#ffffff')};
    font-size: 14px;

    ${({ $stepVariables }) => $stepVariables.isUpcomingStep && 'border: 0.5px solid #cad0d9;'}
`;

const Title = styled.span<{ $stepVariables: StepVariablesType }>`
    font-size: 18px;
    position: absolute;
    top: 40px;
    margin: auto;
    width: 100%;
    text-align: center;
    color: ${({ $stepVariables }) => ($stepVariables.isUpcomingStep ? '#CAD0D9' : '#454F59')};
`;
