import { makeAutoObservable, runInAction } from 'mobx';
import { MuiTelInputCountry } from 'mui-tel-input';
import GeolocationService from '@services/GeolocationService';

type TCounryCode = MuiTelInputCountry | null;

class GeolocationStore {
    countryCode: TCounryCode = null;
    isLoading: boolean = true;

    constructor() {
        makeAutoObservable(this);
        this.getGeo();
    }

    private async getGeo() {
        try {
            const response = await GeolocationService.fetchCountryCodeGetting();

            this.countryCode = response.data.countrycode;
        } catch (error) {
            console.error('Failed to get geolocation', error);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }
}

export default GeolocationStore;
