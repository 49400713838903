import { ZohoDomainEnum, ZohoEnvironmentEnum, ZohoSyncFrequencyEnum } from '../types/ZohoServiceEnums';

export const zohoSyncFequencies = (Object.values(ZohoSyncFrequencyEnum) as Array<keyof typeof ZohoSyncFrequencyEnum>)
    .filter((item) => typeof item === 'string')
    .map((value) => value);

export const zohoDomains = (Object.values(ZohoDomainEnum) as Array<keyof typeof ZohoDomainEnum>)
    .filter((item) => typeof item === 'string')
    .map((value) => value);

export const zohoEnvironments = (Object.values(ZohoEnvironmentEnum) as Array<keyof typeof ZohoEnvironmentEnum>)
    .filter((item) => typeof item === 'string')
    .map((value) => value);
