import { axiosInstance } from './axiosInstance';
import { IExchangeGrantTokenIncomingData } from '@interfaces/IExchangeGrantTokenIncomingData';
import { ISyncServicesRequestIncomingData } from '@interfaces/ISyncServicesRequestIncomingData';
import config from '@config/config';
import { ICompaniesData } from '@store/Companies.store';
import { zohoSyncFequencies } from '@utils/zoho';

interface ISaveCompanyProps {
    name: string;
    slogan?: string | null;
}

export type UpdateCompanyData = {
    id?: string;
    name?: string;
    slogan?: string;
    website?: string;
    chamberOfCommerceNr?: string;
    legalType?: string;
    receptionPhone?: string;
};

class OOConnect {
    getZohoEnvironmentsConfiguration() {
        return zohoSyncFequencies;
    }

    async getSyncServices() {
        return await axiosInstance.get(`${config.OOC_API}/Services`);
    }

    async getActiveAccounts() {
        return await axiosInstance.get(`${config.OOC_API}/zoho/active-accounts`);
    }

    async getCompanies(companyName: string = '') {
        return await axiosInstance.get(`${config.OO_API}/Companies?UserCreatedOnly=true&CompanyName=${companyName}`);
    }

    async getCompanyById(id: string) {
        return await axiosInstance.get(`${config.OO_API}/Companies/${id}`);
    }

    async saveCompany(data: ISaveCompanyProps) {
        return await axiosInstance.post(`${config.OO_API}/Companies`, data);
    }

    async updateCompany({ id, data }: { id: string; data: UpdateCompanyData }) {
        return await axiosInstance.put(`${config.OO_API}/Companies/${id}`, {
            id,
            ...data,
        });
    }

    async updateCompanyLogo({ id, formData }: { id: string; formData: FormData }) {
        return await axiosInstance.put(`${config.OO_API}/Companies/${id}/logo`, formData, {
            headers: {
                'Content-Type': 'multipart-formData',
            },
        });
    }

    async deleteCompany({ id }: { id: ICompaniesData['id'] }) {
        return await axiosInstance.delete(`${config.OO_API}/Companies/${id}`);
    }

    async getAccountDomains() {
        return await axiosInstance.get(`${config.OOC_API}/zoho/domains/configuration`);
    }

    async getEnvironments() {
        return await axiosInstance.get(`${config.OOC_API}/zoho/environments/configuration`);
    }

    async getScopes() {
        return await axiosInstance.get(`${config.OOC_API}/zoho/scopes/configuration`);
    }

    async exchangeGrantToken(data: IExchangeGrantTokenIncomingData) {
        return await axiosInstance.post(`${config.OOC_API}/ZohoService`, data);
    }

    async getSyncServiceRequested() {
        return await axiosInstance.get(`${config.OOC_API}/sync-services/requested`);
    }

    async sendSyncServiceRequest(data: ISyncServicesRequestIncomingData) {
        return await axiosInstance.post(`${config.OOC_API}/sync-services/request`, data);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new OOConnect();
