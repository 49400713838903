import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { Box, Fade } from '@mui/material';
import styles from './Popper.module.scss';

interface IPopperProps {
    renderButton: ReactElement;
    renderDropdown: ReactElement;
    className?: string;
    classActiveButton?: string;
}

export const Popper: FC<IPopperProps> = ({ renderButton, renderDropdown, className, classActiveButton }) => {
    const [show, setShow] = useState<boolean>(false);
    const buttonRef = useRef<HTMLInputElement>(null);
    const dropdownRef = useRef<HTMLInputElement>(null);

    const togglePopper = () => {
        setShow((prev) => {
            return !prev;
        });
    };

    const closePopper = () => {
        setShow(false);
    };

    useEffect(() => {
        const handleClickOutside = (e: Event) => {
            const target = e.target as HTMLDivElement;

            if (
                buttonRef.current &&
                dropdownRef.current &&
                !buttonRef.current.contains(target) &&
                !dropdownRef.current.contains(target)
            ) {
                closePopper();
            }
        };

        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <Box className={`${styles.popper} ${className}`}>
            <div ref={buttonRef} onClick={togglePopper} className={show ? classActiveButton : ''}>
                {renderButton}
            </div>
            <div ref={dropdownRef} className={styles.dropdown}>
                {show && <Fade in={show}>{renderDropdown}</Fade>}
            </div>
        </Box>
    );
};
