import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Button, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { Popup } from '@components/Popup/Popup';
import { Selector } from '@components/Selector/Selector';
import { Input } from '@components/Input/Input';
import { Textarea } from '@components/Textarea/Textarea';
import { AutocompleteTextField } from '@components/AutocompleteTextField/AutocompleteTextField';
import { useDataStore } from '@hooks/useDataStore';
import { validation } from '@utils/validation';
import { Errors } from '@constants/errors';
import { debounce } from 'throttle-debounce';
import ui from '@theme/common/ui.module.scss';
import styles from './ZohoServicePopup.module.scss';
import { LoadingButton } from '@mui/lab';
import { zohoDomains, zohoEnvironments, zohoSyncFequencies } from '@utils/zoho';
import { ZohoDomainEnum, ZohoEnvironmentEnum, ZohoSyncFrequencyEnum } from '../../../../types/ZohoServiceEnums';

interface IValuesForm {
    companyNameOrId: string;
    accountName: string;
    clientId: string;
    syncFrequency: ZohoSyncFrequencyEnum | null;
    clientSecret: string;
    accountDomain: ZohoDomainEnum | null;
    environment: ZohoEnvironmentEnum | null;
    code: string;
}

export const ZohoServicePopup = observer(() => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { addService, notification } = useDataStore();
    const { validateRequired } = validation();

    const formik = useFormik({
        initialValues: {
            companyNameOrId: '', // If a user creates new company, this value will be a name. If existing, it'll select an existing id
            accountName: '',
            clientId: '',
            syncFrequency: null,
            clientSecret: '',
            accountDomain: null,
            environment: null,
            code: '',
        },
        onSubmit: async (values: IValuesForm) => {
            setIsLoading(true);

            const selectedCompany = addService.companies?.filter((company) => company.id === values.companyNameOrId);
            const [currentCompany] = selectedCompany;

            const data = {
                companyId: values.companyNameOrId,
                syncFrequency: values.syncFrequency,
                type: addService.serviceType,
                domain: values.accountDomain,
                environment: values.environment,
                clientId: values.clientId,
                clientSecret: values.clientSecret,
                code: values.code,
            };

            try {
                if (!currentCompany) {
                    const newlyCreatedCompanyId = await addService.saveCompany(values.companyNameOrId);

                    // Re-fetch created companies with a filter on the company name & set value
                    await addService.getCompanies(values.companyNameOrId);
                    const newCreatedCompany = addService.companies?.find(
                        (company) => company.id === newlyCreatedCompanyId,
                    );

                    if (newCreatedCompany === undefined) throw Error("Created new company but couldn't select it");

                    data.companyId = newCreatedCompany.id;
                }

                await addService.exchangeGrantToken(data);
                formik.resetForm();
                addService.toggleZohoServicePopup(false);
                notification.sendNotification({ message: 'Synced Service added' });
            } catch (error) {
                // addService.toggleZohoServicePopup(false);
                notification.sendNotification({ message: 'Something went wrong', type: 'error' });
                console.error('Failed to add ZOHO Service', error);
            }

            setIsLoading(false);
        },
        validate: (values) => {
            const errorMessages: Record<string, string> = {};

            addService.activeAccounts?.forEach((account) => {
                const formAccountName = values.accountName.toLowerCase();
                const incomingAccountName = account.zohoAccountName.toLocaleLowerCase();

                if (formAccountName === incomingAccountName) {
                    errorMessages.accountName = Errors.AccountName;
                }
            });

            if (!validateRequired(values.accountName)) {
                errorMessages.accountName = Errors.RequiredField;
            }

            if (!validateRequired((values.syncFrequency ?? '').toString())) {
                errorMessages.syncType = Errors.RequiredField;
            }

            if (!validateRequired((values.accountDomain ?? '').toString())) {
                errorMessages.accountDomain = Errors.RequiredField;
            }

            if (!validateRequired((values.environment ?? '').toString())) {
                errorMessages.environment = Errors.RequiredField;
            }

            if (!validateRequired(values.clientSecret)) {
                errorMessages.clientSecret = Errors.RequiredField;
            }

            if (!validateRequired(values.code)) {
                errorMessages.code = Errors.RequiredField;
            }
            return errorMessages;
        },
    });

    const handleBack = () => {
        addService.toggleZohoServicePopup(false);
        addService.toggleServicePopup(true);
        formik.resetForm();
    };

    const handleClose = useCallback(() => {
        addService.toggleZohoServicePopup(false);
        formik.resetForm();
    }, []);

    const handleCompanySearch = debounce(250, (value: string) => {
        addService.getCompanies(value);
    });

    const handleEmployerCreation = (value: string) => {
        if (!value.length) return;

        addService.setTypedNewEmployer(value);
        addService.toggleEmployerConfirmationPopup(true);
    };

    useEffect(() => {
        if (!addService.isZohoServicePopupShown) return;

        addService.getCompanies();
        addService.getActiveAccounts();
    }, [addService.isZohoServicePopupShown]); // eslint-disable-line

    const formatCompaniesOptions = useCallback(() => {
        const activeAccounts = addService.activeAccounts?.map((activeAccount) => {
            return activeAccount.companyId;
        });

        return addService.companies?.map((item) => ({
            value: item.id,
            text: item.name,
            disabled: activeAccounts?.includes(item.id),
        }));
    }, [addService.companies, addService.activeAccounts]);

    // The comment causes by hardcoded options of the type field
    // const formatSyncTypesOptions = useCallback(
    //     () =>
    //         addService.syncTypes?.map((item: any) => ({
    //             value: item.name,
    //             text: item.name,
    //         })),
    //     [addService.syncTypes],
    // );

    const PopupFooter = () => (
        <Box className={ui.popupFooter}>
            <Typography variant="body2" className={ui.helperText}>
                step 2 of 3
            </Typography>
            <Box className={ui.buttonGroup}>
                <Button variant="outlined" className={ui.buttonOutlined} onClick={handleBack}>
                    Back
                </Button>
                <LoadingButton
                    type="submit"
                    form="ZohoServiceForm"
                    variant="contained"
                    disabled={!(formik.isValid && formik.dirty)}
                    loading={isLoading}
                    className={ui.buttonContained}
                    sx={{
                        '&.MuiButtonBase-root.Mui-disabled': {
                            backgroundColor: 'var(--c-grey-normal)',
                        },
                    }}
                >
                    Add
                </LoadingButton>
            </Box>
        </Box>
    );

    return (
        <Popup
            state={addService.isZohoServicePopupShown}
            onClose={handleClose}
            title="Zoho service configuration"
            renderFooter={<PopupFooter />}
            sizeContainer="large"
        >
            <form id="ZohoServiceForm" onSubmit={formik.handleSubmit}>
                <Grid container columnSpacing={3.75} alignItems="flex-start">
                    <Grid item xs={6}>
                        <Grid item marginBottom="30px">
                            <AutocompleteTextField
                                name="companyNameOrId"
                                id="zoho-service-company"
                                setFieldValue={formik.setFieldValue}
                                options={formatCompaniesOptions}
                                label="Your Employer"
                                className={styles.input}
                                onChangeSearchCompany={handleCompanySearch}
                                onChangeEmployerCreation={handleEmployerCreation}
                            />
                        </Grid>
                        <Grid item marginBottom="30px">
                            <Input
                                name="accountName"
                                onChange={formik.handleChange}
                                id="zoho-service-accountName"
                                value={formik.values.accountName}
                                label="Account name"
                                error={formik.errors.accountName}
                                touched={formik.touched.accountName}
                                onBlur={formik.handleBlur}
                                className={styles.input}
                            />
                        </Grid>
                        <Grid item marginBottom="30px">
                            <Selector
                                name="syncFrequency"
                                onChange={formik.handleChange}
                                value={formik.values.syncFrequency}
                                id="zoho-service-sync-type"
                                label="Sync type"
                                className={styles.input}
                                options={zohoSyncFequencies.map((value) => ({
                                    value: value,
                                    text: value,
                                }))}
                            />
                        </Grid>
                        <Grid item xs={12} marginBottom="30px">
                            <Selector
                                name="accountDomain"
                                onChange={formik.handleChange}
                                value={formik.values.accountDomain}
                                id="zoho-service-account-domain"
                                label="Account Domain"
                                className={styles.input}
                                options={zohoDomains.map((value) => ({
                                    value: value,
                                    text: value,
                                }))}
                            />
                        </Grid>
                        <Grid item xs={12} marginBottom="30px">
                            <Selector
                                name="environment"
                                onChange={formik.handleChange}
                                value={formik.values.environment}
                                id="zoho-service-account-domain"
                                label="Environment"
                                className={styles.input}
                                options={zohoEnvironments.map((value) => ({
                                    value: value,
                                    text: value,
                                }))}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid item marginBottom="30px">
                            <Input
                                name="clientId"
                                onChange={formik.handleChange}
                                id="zoho-service-client-id"
                                value={formik.values.clientId}
                                label="Client ID"
                                error={formik.errors.clientId}
                                touched={formik.touched.clientId}
                                onBlur={formik.handleBlur}
                                className={styles.input}
                            />
                        </Grid>
                        <Grid item marginBottom="30px">
                            <Input
                                name="clientSecret"
                                onChange={formik.handleChange}
                                id="zoho-service-client-secter"
                                value={formik.values.clientSecret}
                                label="Client Secret"
                                error={formik.errors.clientSecret}
                                touched={formik.touched.clientSecret}
                                onBlur={formik.handleBlur}
                                className={styles.input}
                            />
                        </Grid>
                        <Grid item marginBottom="30px">
                            <Textarea
                                name="code"
                                value={formik.values.code}
                                id="zoho-service-grant-token"
                                label="Grant Token"
                                maxRows={11.5}
                                minRows={11.5}
                                maxLength={100}
                                setFieldValue={formik.setFieldValue}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Popup>
    );
});
