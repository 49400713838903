import { observer } from 'mobx-react-lite';
import { TableContainer, Table, TableBody } from '@mui/material';
import { useDataStore } from '@hooks/useDataStore';
import { Spinner } from '@components/Spinner/Spinner';
import { LogTableHead } from './LogTableHead';
import { LogTableRow } from './LogTableRow';
import ui from '@theme/common/ui.module.scss';

export const LogTable = observer(() => {
    const { syncLogs } = useDataStore();

    return (
        <Spinner isLoading={syncLogs.isContentLoading}>
            <TableContainer className={ui.tableContainer}>
                <Table>
                    <LogTableHead />
                    <TableBody>
                        <LogTableRow />
                    </TableBody>
                </Table>
            </TableContainer>
        </Spinner>
    );
});
