import { FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PhoneNumberInput } from '@components/PhoneNumberInput/PhoneNumberInput';
import { useDataStore } from '@hooks/useDataStore';
import ui from '@theme/common/ui.module.scss';
import styles from './LoginForm.module.scss';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { ICognitoError } from '@store/Authorization.store';
import { Errors } from '@constants/errors';

interface ILoginFormProps {
    comeToVerification: () => void;
    setCognitoUser: (user: CognitoUser) => void;
}

export const LoginForm: FC<ILoginFormProps> = observer(({ comeToVerification, setCognitoUser }) => {
    const { authorization } = useDataStore();
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [failedPhoneNumber, setFailedPhoneNumber] = useState<boolean>(false);
    const [failedVerificationMessage, setFailedVerificationMessage] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onCatchError = useCallback((state: boolean) => {
        setFailedPhoneNumber(state);
    }, []);

    const onChangePhoneNumber = useCallback((value: string) => {
        setPhoneNumber(value);
    }, []);

    const onClickSubmitFormHandler = (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        const formattedPhoneNumber = phoneNumber.replace(/\s+/g, '');

        authorization
            .loginWithPhoneNumber(formattedPhoneNumber)
            .then((user) => {
                if (!user) return;
                setCognitoUser(user);
                comeToVerification();
            })
            .catch((e: ICognitoError) => {
                console.warn(e);
                if (
                    e.name === 'UserLambdaValidationException' &&
                    e.message ===
                        'CreateAuthChallenge failed with error Please wait a little before requesting another code.'
                ) {
                    setFailedVerificationMessage(Errors.RateLimitedCode);
                }
                if (e.name === 'UserNotFoundException' && e.message === 'User does not exist.') {
                    // Ugly but does the following: Signup the user, recall the login
                    authorization.signUpWithPhoneNumber(formattedPhoneNumber).then(() => {
                        authorization.loginWithPhoneNumber(formattedPhoneNumber).then((user) => {
                            if (!user) return;
                            setCognitoUser(user);
                            comeToVerification();
                        });
                    });
                }
            })
            .finally(() => {
                setIsLoading(false);
                setFailedPhoneNumber(false);
            });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.form}>
                <Typography variant="h3" className={styles.title}>
                    Log in or Sign up
                </Typography>
                <Typography paragraph={true} className={styles.description}>
                    Just enter your phone number.
                </Typography>
                <form onSubmit={onClickSubmitFormHandler}>
                    <PhoneNumberInput
                        onCatchError={onCatchError}
                        onChangePhoneNumber={onChangePhoneNumber}
                        error={failedPhoneNumber}
                        label="Phone Number"
                    />
                    {failedVerificationMessage && (
                        <span className={styles.errorMessage}>{failedVerificationMessage}</span>
                    )}
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        disabled={!failedPhoneNumber}
                        loading={isLoading}
                        size={'large'}
                        className={ui.buttonOutlinedPrimary}
                    >
                        Verify Number
                    </LoadingButton>
                </form>
            </div>
        </div>
    );
});
