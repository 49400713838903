import { useRef } from 'react';
import { FC, memo, useState } from 'react';
import { Box, ButtonBase, InputLabel, IconButton, Typography } from '@mui/material';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import ui from '@theme/common/ui.module.scss';
import styles from './UploadFile.module.scss';

interface IUploadFileProps {
    name: string;
    id: string;
    error: string | undefined;
    label?: string;
    maxSize?: number;
    className?: string;
    setFieldValue: (key: string, value: string) => void;
}

interface IFileDetail {
    name: string;
    size: number;
}

export const UploadFile: FC<IUploadFileProps> = memo(
    ({ name, maxSize = 5, id, error, label, className, setFieldValue }) => {
        const [fileDetail, setFileDetail] = useState<IFileDetail | null>(null);
        const fileRef = useRef<HTMLInputElement>(null!);

        const onChangeFile = (event: any) => {
            const files = event.target.files;

            if (files && files[0]) {
                const file = files[0];

                setFileDetail({
                    name: file.name,
                    size: file.size,
                });

                setFieldValue(name, file);
            }
        };

        const removeSelectedFile = () => {
            fileRef.current.value = '';
            setFieldValue(name, '');
            setFileDetail(null);
        };

        return (
            <Box className={`${className} ${styles.uploadFile} ${error ? styles.uploadFileError : ''}`}>
                {label && <InputLabel className={ui.inputLabel}>{label}</InputLabel>}
                <Box className={`${styles.uploadFileBox} ${fileDetail ? styles.selectedFile : ''}`}>
                    {fileDetail && (
                        <Box className={styles.uploadFileResult}>
                            <Typography variant="caption" className={styles.uploadFileName}>
                                {fileDetail.name}
                            </Typography>
                            <IconButton className={styles.uploadFileDelete} onClick={removeSelectedFile}>
                                <DeleteIcon className={styles.uploadFileDeleteIcon} />
                            </IconButton>
                        </Box>
                    )}
                    <ButtonBase component="label" className={styles.uploadFileButton}>
                        <input
                            hidden
                            ref={fileRef}
                            accept="image/jpeg,image/png,.gif"
                            type="file"
                            name={name}
                            id={id}
                            onChange={onChangeFile}
                        />
                        <Box component="span" className={styles.uploadFileButtonText}>
                            {fileDetail ? 'Change' : 'Select file'}
                        </Box>
                    </ButtonBase>
                </Box>
                <Typography variant="caption" className={styles.uploadFileHelperText}>
                    {error ? error : `Png, jpg or gif. (max size ${maxSize} mb)`}
                </Typography>
            </Box>
        );
    },
);
