import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Button } from '@mui/material';
import { useFormik } from 'formik';
import { Popup } from '@components/Popup/Popup';
import { Input } from '@components/Input/Input';
import { FieldList } from '@components/FieldList/FieldList';
import { useDataStore } from '@hooks/useDataStore';
import { validation } from '@utils/validation';
import { Errors } from '@constants/errors';
import ui from '@theme/common/ui.module.scss';
import styles from './RequestServicePopup.module.scss';

interface IFormValues {
    serviceId: string;
    serviceName: string;
    serviceLink: string;
}

export const RequestServicePopup = observer(() => {
    const { addService, notification } = useDataStore();
    const { validateRequired } = validation();

    useEffect(() => {
        if (!addService.isRequestServicePopupShown) return;

        addService.getSyncServiceRequested();
    }, [addService.isRequestServicePopupShown]);

    const formik = useFormik({
        initialValues: {
            serviceId: '',
            serviceName: '',
            serviceLink: '',
        },
        onSubmit: (values: IFormValues) => {
            addService
                .sendSyncServiceRequest({
                    serviceId: values.serviceId,
                    serviceName: values.serviceName,
                    serviceLink: values.serviceLink,
                })
                .then(() => {
                    formik.resetForm();
                    addService.toggleRequestServicePopup(false);
                    notification.sendNotification({ message: 'Service requested' });
                });
        },
        validate: (values: IFormValues) => {
            const errors: Record<string, string> = {};

            if (!validateRequired(values.serviceId)) {
                errors.service = Errors.RequiredField;
            }

            return errors;
        },
    });

    const handleBack = () => {
        addService.toggleRequestServicePopup(false);
        addService.toggleServicePopup(true);
        formik.resetForm();
    };

    const handleClose = useCallback(() => {
        addService.toggleRequestServicePopup(false);
        formik.resetForm();
    }, []);

    const formatListRequest = useCallback(() => {
        return addService.syncServicesRequested?.map((category, index) => {
            const services = category.requestedServices?.map((service) => ({
                id: service.id,
                value: service.id,
                text: service.name,
            }));

            return {
                id: `${index}`,
                title: category.category,
                options: services,
            };
        });
    }, [addService.syncServicesRequested]);

    useEffect(() => {
        if (!formik.values.serviceId) return;

        addService.syncServicesRequested.forEach((category) => {
            const service = category.requestedServices.find((service) => service.id === formik.values.serviceId);

            if (service) {
                formik.setFieldValue('serviceName', service.name);
                formik.setFieldValue('serviceLink', service.link);
            }
        });
    }, [formik.values.serviceId]);

    const PopupFooter = () => (
        <Box className={ui.popupFooter}>
            <Button variant="outlined" className={ui.buttonOutlined} onClick={handleBack}>
                Back
            </Button>
            <Button
                type="submit"
                form="RequestServiceForm"
                variant="contained"
                disabled={!(formik.isValid && formik.dirty)}
                className={ui.buttonContained}
                sx={{
                    '&.MuiButtonBase-root.Mui-disabled': {
                        backgroundColor: 'var(--c-grey-normal)',
                        color: 'var(--c-grey-dark)',
                    },
                }}
            >
                Request
            </Button>
        </Box>
    );

    return (
        <Popup
            state={addService.isRequestServicePopupShown}
            onClose={handleClose}
            title="Request service"
            renderFooter={<PopupFooter />}
        >
            <form id="RequestServiceForm" onSubmit={formik.handleSubmit}>
                <Typography marginBottom="30px" className={styles.helperText} variant="body1">
                    Select a service option
                    <br />
                    that you want us to add
                </Typography>
                {addService.syncServicesRequested && (
                    <FieldList
                        name="serviceId"
                        className={styles.fieldList}
                        onChange={formik.handleChange}
                        fields={formatListRequest()}
                    />
                )}
                {!addService.syncServicesRequested && (
                    <Typography marginBottom="40px">The services are missing</Typography>
                )}
                <Typography marginBottom="15px" className={styles.helperText} variant="body1">
                    Or enter service option
                </Typography>
                <Input
                    name="serviceName"
                    onChange={formik.handleChange}
                    id="request-service-name"
                    value={formik.values.serviceName}
                    label="service name"
                    error={formik.errors.serviceName}
                    touched={formik.touched.serviceName}
                    onBlur={formik.handleBlur}
                    className={styles.input}
                />
                <Input
                    name="serviceLink"
                    onChange={formik.handleChange}
                    id="request-service-link"
                    value={formik.values.serviceLink}
                    label="service link"
                    error={formik.errors.serviceLink}
                    touched={formik.touched.serviceLink}
                    onBlur={formik.handleBlur}
                    className={styles.input}
                />
            </form>
        </Popup>
    );
});
