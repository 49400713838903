import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#00A0E1',
            contrastText: '#FFFFFF',
        },
    },
    shape: {
        borderRadius: 12,
    },
    typography: {
        fontFamily: 'Source Sans Pro',
        button: {
            height: 48,
            fontSize: 18,
            fontWeight: 600,
            textTransform: 'none',
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
        },
    },
});
