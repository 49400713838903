import { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import { ListRoutes } from '@components/AppRouter/routes';
import VerificationInput from 'react-verification-input';
import { useDataStore } from '@hooks/useDataStore';
import { Errors } from '@constants/errors';
import ui from '@theme/common/ui.module.scss';
import styles from './Verification.module.scss';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

interface IVerificationProps {
    comeBackCallback: () => void;
    cognitoUser?: CognitoUser;
}

export const Verification: FC<IVerificationProps> = observer(({ comeBackCallback, cognitoUser }) => {
    const navigation = useNavigate();
    const { authorization, user } = useDataStore();
    const [code, setCode] = useState<string>('');
    const [newCodeSent, setNewCodeSent] = useState<boolean>(false);
    const [failedVerifyCode, setFailedVerifyCode] = useState<boolean>(false);
    const [failedVerificationMessage, setFailedVerificationMessage] = useState<string>('');

    const resendNewCode = () => {
        setNewCodeSent(true);
        setFailedVerifyCode(false);
        setCode('');

        Auth.resendSignUp(authorization.phoneNumber);
    };

    const onChangeVerification = (code: string) => {
        setFailedVerifyCode(false);
        setCode(code);

        if (code.length === 6 && cognitoUser) {
            authorization
                .verifyCode({ cognitoUser, code })
                .then(async () => {
                    await user.getAccount();
                    navigation(ListRoutes.Onboarding);
                })
                .catch((e) => {
                    if (e === 'No current user') {
                        setFailedVerifyCode(true);
                        setFailedVerificationMessage(Errors.IncorrectVerificationCode);
                        setCode('');
                        return;
                    }
                    if (e.toString().startsWith('NotAuthorizedException: Invalid session for the user.')) {
                        comeBackCallback();
                        setFailedVerificationMessage(Errors.IncorrectVerificationCode);
                    }
                });
        }
    };

    const styledCharacter = failedVerifyCode ? styles.failedCharacter : styles.character;

    return (
        <Box className={styles.wrapper}>
            <Button
                variant="outlined"
                className={`${styles.backButton} ${ui.buttonOutlined}`}
                onClick={comeBackCallback}
            >
                Go Back
            </Button>
            <Box className={styles.inner}>
                <Typography className={styles.title} variant="h3">
                    Verification Code
                </Typography>
                <Typography className={styles.description} paragraph={true}>
                    We sent a code to <span>{authorization.phoneNumber}</span>
                </Typography>
                <Typography className={styles.description} paragraph={true}>
                    Please enter it to verify you phone number and login the app.
                </Typography>
                <Box className={styles.form}>
                    <label className={styles.formLabel} htmlFor="verification">
                        Enter verification Code
                    </label>
                    <VerificationInput
                        validChars="0-9"
                        inputProps={{ type: 'tel' }}
                        length={6}
                        value={code}
                        placeholder={'0'}
                        autoFocus={true}
                        onChange={onChangeVerification}
                        classNames={{
                            character: styledCharacter,
                            characterInactive: styles.characterInactive,
                        }}
                    />
                    {failedVerifyCode && <span className={styles.errorMessage}>{failedVerificationMessage}</span>}
                    {newCodeSent && (
                        <Typography className={styles.formNewCodeMessage} paragraph={true}>
                            We just sent a new code!
                        </Typography>
                    )}
                    <Button className={styles.formButton} onClick={resendNewCode}>
                        Resend Code
                    </Button>
                </Box>
            </Box>
        </Box>
    );
});
