/*
    The expected response of function is
    * true - is valid
    * false - is invalid
*/
export const validation = () => {
    const validateRequired = (text: string): boolean => {
        return text.length >= 1;
    };

    const validateLength = (text: string, length: number): boolean => {
        return text.length < length;
    };

    const validateEmail = (email: string): boolean => {
        const validation = email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
        const isEmptyEmail = email.length < 1;

        return Boolean(validation) || isEmptyEmail;
    };

    const validateFileSize = (file: Blob | string, maxSize: 5): boolean => {
        const MB = 1000000;

        if (file instanceof Blob) {
            const fileSize = file.size;
            const convertToMb = fileSize / MB;

            return convertToMb < maxSize;
        }

        return true;
    };

    return {
        validateRequired,
        validateLength,
        validateEmail,
        validateFileSize,
    };
};
