import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Typography } from '@mui/material';
import { Popup } from '@components/Popup/Popup';
import { useDataStore } from '@hooks/useDataStore';
import ui from '@theme/common/ui.module.scss';
import { LoadingButton } from '@mui/lab';

const DeleteCompanyPopup = observer(() => {
    const { notification, companies } = useDataStore();
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const handleDelete = () => {
        if (!companies.companyIdToDelete) {
            return notification.sendNotification({
                type: 'error',
                message: 'An error occurred while trying to delete this company. Please try again later.',
            });
        }

        setIsLoadingDelete(true);
        companies
            .deleteCompany({ id: companies.companyIdToDelete })
            .then(() => {
                notification.sendNotification({
                    type: 'success',
                    message: `You successfully deleted company "${companies.companyNameToDelete}"`,
                });
                companies.getMyCompanies();
            })
            .catch(() => {
                notification.sendNotification({
                    type: 'error',
                    message: `An error occurred while trying to delete "${companies.companyNameToDelete}". Please try again later.`,
                });
            })
            .finally(() => {
                companies.setIsDeleteCompanyPopupShown(false);
                setIsLoadingDelete(false);
            });
    };

    const handleClose = useCallback(() => {
        companies.setIsDeleteCompanyPopupShown(false);
    }, []); // eslint-disable-line

    const PopupFooter = () => (
        <Box className={ui.popupFooter}>
            <Box className={ui.buttonGroup}>
                <Button variant="outlined" onClick={handleClose} className={ui.buttonOutlined}>
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    onClick={handleDelete}
                    className={ui.buttonContained}
                    loading={isLoadingDelete}
                    sx={{
                        '&.MuiButtonBase-root': {
                            backgroundColor: 'var(--c-secondary-red)',
                            color: 'white',
                        },
                    }}
                >
                    Delete
                </LoadingButton>
            </Box>
        </Box>
    );

    return (
        <Popup
            state={companies.isDeleteCompanyPopupShown}
            onClose={handleClose}
            title={`Are you sure you want to delete "${companies.companyNameToDelete}"?`}
            renderFooter={<PopupFooter />}
        >
            <Typography>
                You are about to permanently delete "{companies.companyNameToDelete}". Are you sure you want to delete
                this company?
            </Typography>
        </Popup>
    );
});

export default DeleteCompanyPopup;
