let timer: ReturnType<typeof setTimeout> = null!;

export const delay = (ms: number) => {
    clearTimeout(timer);

    return new Promise((resolve) => {
        timer = setTimeout(resolve, ms);

        return timer;
    });
};
