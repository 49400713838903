import { FC, memo, useMemo } from 'react';
import { Box, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { themeProvider } from './theme.styled';
import { ReactComponent as DropdownIcon } from '@assets/icons/dropdown-icon.svg';
import ui from '@theme/common/ui.module.scss';
import styles from './Selector.module.scss';
import { ErrorMessage } from '@components/ErrorMessage/ErrorMessage';

type TPopupSize = 'large' | 'small';

interface IOption {
    value: string;
    text: string;
}

interface ISelectorProps {
    name: string;
    value: any;
    onChange: (e: SelectChangeEvent<string>) => void;
    options: IOption[] | (() => IOption[]);
    label?: string;
    id?: string;
    placeholder?: string;
    className?: string;
    size?: TPopupSize;
    multiple?: boolean;
    disabled?: boolean;
    touched?: boolean;
    error?: string;
}

export const Selector: FC<ISelectorProps> = memo(
    ({
        name,
        value,
        onChange,
        options,
        label,
        id,
        placeholder = 'Select',
        size = 'large',
        multiple = false,
        className,
        disabled,
        touched,
        error,
    }) => {
        const hasError = useMemo(() => !!error && touched, [error, touched]);
        const theme = useMemo(
            () => themeProvider[size]({ disabled, placeholder, error: hasError }),
            [size, disabled, placeholder, hasError],
        );

        const definedOptionsField = typeof options === 'function' ? options() : options;

        return (
            <Box marginBottom="24px" className={className}>
                {label && (
                    <InputLabel id={id} className={ui.inputLabel}>
                        {label}
                    </InputLabel>
                )}
                <Select
                    fullWidth
                    labelId={id}
                    name={name}
                    value={value}
                    onChange={onChange}
                    IconComponent={DropdownIcon}
                    disabled={disabled}
                    sx={theme}
                    multiple={multiple}
                    error={hasError}
                    MenuProps={{
                        classes: {
                            paper: styles.dropdown,
                        },
                    }}
                >
                    {definedOptionsField?.map((option: IOption, index) => (
                        <MenuItem
                            key={index}
                            value={option.value}
                            sx={{
                                '&': {
                                    display: 'flex',
                                    width: '100%',
                                },
                                '& svg': {
                                    marginLeft: 'auto',
                                    opacity: '0',
                                    transitionDuration: '200ms',
                                },
                                '&.Mui-selected': {
                                    color: 'var(--c-primary-sky-blue)',
                                },
                                '&.Mui-selected svg': {
                                    opacity: '1',
                                },
                            }}
                        >
                            {option.text}
                        </MenuItem>
                    ))}
                </Select>

                {hasError && <ErrorMessage>{error}</ErrorMessage>}
            </Box>
        );
    },
);
