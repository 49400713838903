import axios, { AxiosInstance } from 'axios';
import { Auth } from 'aws-amplify';

const axiosInstance: AxiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    async (config: any) => {
        const access_token = (await Auth.currentSession()).getIdToken().getJwtToken();

        config.headers = {
            Authorization: `Bearer ${access_token}`,
            Accept: 'application/json',
        };

        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

export { axiosInstance };
