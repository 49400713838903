import { ReactElement, FC } from 'react';
import { Box, CircularProgress } from '@mui/material';

interface ISpinnerProps {
    children: ReactElement;
    isLoading: boolean;
}

export const Spinner: FC<ISpinnerProps> = ({ isLoading, children }) => {
    if (isLoading)
        return (
            <Box
                sx={{
                    width: '100%',
                    paddingY: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress
                    sx={{
                        '& svg': {
                            color: 'var(--c-primary-sky-blue)',
                        },
                    }}
                />
            </Box>
        );

    return children;
};
