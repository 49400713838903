import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListRoutes } from '@components/AppRouter/routes';

export const NotFound = () => {
    const navigation = useNavigate();

    useEffect(() => {
        navigation(ListRoutes.Account);
    }, [navigation]);

    return <></>;
};
