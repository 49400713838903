import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './Footer.module.scss';

export const Footer = () => {
    const [currentYear, setCurrentYear] = useState<number | null>(null);

    useEffect(() => {
        setCurrentYear(new Date().getFullYear());
    }, []);

    return (
        <Box className={styles.footer}>
            <Typography variant="caption" className={styles.footerLaw}>
                © {currentYear} OnlyOnce
            </Typography>
        </Box>
    );
};
