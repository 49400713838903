import { FC, ReactNode, memo } from 'react';
import { FormHelperText } from '@mui/material';

import styles from './ErrorMessage.module.scss';

type Props = {
    children: ReactNode;
};

export const ErrorMessage: FC<Props> = memo(({ children }) => {
    return <FormHelperText className={styles.errorMessage}>{children}</FormHelperText>;
});
