import React, { useEffect, useMemo, useState } from 'react';

import { FormContext, FormContextState } from './FormContext';
import OOApi, { User } from '@services/OOApi';
import { LicenseNumberForm } from '../components/Form/LicenseNumberForm/LicenseNumberForm';
import { CompanyInfoForm } from '../components/Form/CompanyInfoForm/CompanyInfoForm';
import { ProfileInfoForm } from '../components/Form/ProfileInfoForm/ProfileInfoForm';

type Props = {
    children: React.ReactNode;
};

const FormProvider = (props: Props) => {
    const [step, setStep] = useState<FormContextState['step']>(0);
    const [user, setUser] = useState<User | null>(null);

    const forms: FormContextState['forms'] = useMemo(() => {
        return [
            { title: 'Enter license key', component: <LicenseNumberForm /> },
            { title: 'Your company info', component: <CompanyInfoForm /> },
            { title: 'Tell us about you', component: <ProfileInfoForm /> },
        ];
    }, []);

    const [formValues, setFormValues] = useState<FormContextState['formValues']>({
        // First step
        licenseNumber: '',
        accept: false,
        // Account info
        file: null,
        firstName: '',
        lastName: '',
        gender: null,
        email: '',
        // Company info
        companyName: '',
        companyId: '',
        logo: {} as File,
        headline: '',
    });

    useEffect(() => {
        OOApi.getAccount().then((account) => {
            setUser(account.data);

            if (account.data) {
                setFormValues((prev) => ({
                    ...prev,
                    firstName: account.data?.firstName || '',
                    lastName: account.data?.lastName || '',
                    gender: account.data?.gender || null,
                }));
            }
        });
    }, []);

    const value = useMemo(() => {
        return { forms, step, setStep, formValues, setFormValues, user };
    }, [forms, step, setStep, formValues, setFormValues, user]);

    return <FormContext.Provider value={value} {...props} />;
};

export default FormProvider;
