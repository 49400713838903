import { ChangeEvent, FC, Fragment } from 'react';
import { Radio, FormControl, FormLabel, RadioGroup, Typography } from '@mui/material';
import styles from './FieldList.module.scss';

const FieldListStyled = {
    '&': {
        width: '100%',
        justifyContent: 'flex-start',
        height: '42px',
        padding: '5px 16px',
        borderRadius: '0',
        transitionDuration: '200ms',
    },
    '& .MuiTypography-root': {
        color: 'var(--c-primary-black)',
        fontSize: '18px',
        transitionDuration: '200ms',
    },
    '&.Mui-checked': {
        backgroundColor: 'var(--c-primary-sky-blue)',
    },
    '&.Mui-checked .MuiTypography-root': {
        color: 'var(--c-primary-white)',
    },
};

interface IOptions {
    id: string;
    value: string;
    text: string;
}

interface IFields {
    id: string;
    title: string;
    options: IOptions[];
}

interface IFieldListProps {
    name: string;
    fields: IFields[];
    className?: string;
    onChange: (e: ChangeEvent<any>) => void;
}

export const FieldList: FC<IFieldListProps> = ({ name, fields, className, onChange }) => {
    const FieldListContent = ({ text }: { text: string }) => {
        return <Typography variant="caption">{text}</Typography>;
    };

    return (
        <FormControl className={`${styles.fieldList} ${className}`}>
            <RadioGroup className={styles.fieldListContent} name={name} onChange={onChange}>
                {fields.map((field) => (
                    <Fragment key={field.id}>
                        <FormLabel className={styles.fieldListLabel}>{field.title}</FormLabel>
                        {field.options.map((option) => (
                            <Radio
                                key={option.id}
                                icon={<FieldListContent text={option.text} />}
                                checkedIcon={<FieldListContent text={option.text} />}
                                disableRipple={true}
                                sx={FieldListStyled}
                                value={option.value}
                            />
                        ))}
                    </Fragment>
                ))}
            </RadioGroup>
        </FormControl>
    );
};
