import { memo, ChangeEvent, FC, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { Input } from '@components/Input/Input';
import { IFields, IValues } from '@interfaces/IMultipleFields';
import { ReactComponent as RemoveIcon } from '@assets/icons/modal-close.svg';
import styles from './MultipleFields.module.scss';

interface IFieldsProps extends IFields {
    fieldKey: number;
    label: string;
    error: string;
    onSetFieldValueHandler: () => void;
}

export const FieldItem: FC<IFieldsProps> = memo(
    ({ uniqueKey, setValues, fieldKey, label, removeField, error, onSetFieldValueHandler }) => {
        const [valueItem, setValueItem] = useState<string>('');

        const onChange = (e: ChangeEvent<any>) => {
            const value = e.target.value;

            setValueItem(value);

            setValues((prevState: IValues) => {
                return {
                    ...prevState,
                    [uniqueKey]: { value },
                };
            });
        };

        const onBlur = () => {
            onSetFieldValueHandler();
        };

        const removeFieldHandler = () => {
            removeField(fieldKey, uniqueKey);
        };

        return (
            <Box className={styles.field}>
                <Input
                    name="fields[]"
                    onChange={onChange}
                    id={`multiple-${uniqueKey}`}
                    value={valueItem}
                    label={label}
                    error={error}
                    touched={true}
                    className={styles.fieldInput}
                    onBlur={onBlur}
                />
                <IconButton
                    aria-label="delete"
                    disableRipple={true}
                    onClick={removeFieldHandler}
                    className={styles.fieldButton}
                >
                    <RemoveIcon className={styles.fieldIcon} />
                </IconButton>
            </Box>
        );
    },
);
