import { Routes, Route } from 'react-router-dom';
import { Login } from '@pages/Login/Login';
import { Onboarding } from '@pages/Onboarding/Onboarding';
import { Account } from '@pages/Account/Account';
import { SyncServices } from '@pages/SyncServices/SyncServices';
import { SyncLog } from '@pages/SyncLog/SyncLog';
import { NotFound } from '@pages/NotFound/NoutFound';
import MyAccount from '@pages/Account/MyAccount';
import MyCompanies from '@pages/Account/MyCompanies';
import { ListRoutes } from './routes';

export const AppRouter = () => {
    return (
        <Routes>
            <Route path={ListRoutes.Login} element={<Login />} />
            <Route path={ListRoutes.Onboarding} element={<Onboarding />} />
            <Route path={ListRoutes.Account} element={<Account />}>
                <Route path={ListRoutes.MyAccount} element={<MyAccount />} />
                <Route path={ListRoutes.MyCompanies} element={<MyCompanies />} />
            </Route>
            <Route path={ListRoutes.SyncService} element={<SyncServices />} />
            <Route path={ListRoutes.SyncLog} element={<SyncLog />} />
            <Route path={ListRoutes.NotFound} element={<NotFound />} />
        </Routes>
    );
};
