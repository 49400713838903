import { useEffect, useMemo } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { TableContainer, Table, TableBody, Typography } from '@mui/material';
import { ServiceTableHead } from './ServiceTableHead';
import { ServiceTableRow } from './ServiceTableRow';
import { useDataStore } from '@hooks/useDataStore';
import ui from '@theme/common/ui.module.scss';

export const ServiceTable = observer(() => {
    const { syncServices, addService } = useDataStore();

    const services = useMemo(() => toJS(syncServices.syncServicesMainData), [syncServices.syncServicesMainData]);

    useEffect(() => {
        syncServices.getSyncServices();
    }, [addService.addedService]); // eslint-disable-line

    if (!services) {
        return <Typography>No service created yet.</Typography>;
    }

    return (
        <TableContainer className={ui.tableContainer}>
            <Table>
                <ServiceTableHead />
                <TableBody>
                    {services.map((service) => (
                        <ServiceTableRow
                            key={service.id}
                            serviceId={service.id}
                            accountName={service.company.name}
                            serviceName={service.type}
                            syncFrequency={service.syncFrequency}
                            status={service.status}
                            adminName="-"
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
});
