import { Box, Typography, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Header } from '@components/Header/Header';
import { Navbar } from '@components/Navbar/Navbar';
import { ServiceTable } from './components/ServiceTable/ServiceTable';
import { ArchivePopup } from './components/ArchivePopup/ArchivePopup';
import { ServicesPopup } from './components/ServicesPopup/ServicesPopup';
import { ZohoServicePopup } from './components/ZohoServicePopup/ZohoServicePopup';
import { EmailServicePopup } from './components/EmailServicePopup/EmailServicePopup';
import { RequestServicePopup } from './components/RequestServicePopup/RequestServicePopup';
import { WriteToUsPopup } from '@components/WriteToUsPopup/WriteToUsPopup';
import { useDataStore } from '@hooks/useDataStore';
import ui from '@theme/common/ui.module.scss';
import styles from './SyncServices.module.scss';

export const SyncServices = observer(() => {
    const { addService } = useDataStore();

    const addNewService = () => {
        addService.toggleServicePopup(true);
    };

    return (
        <Box className={ui.layoutContainer}>
            <Box className={ui.layoutHeader}>
                <Header />
            </Box>
            <Box className={ui.layoutNavbar}>
                <Navbar />
            </Box>
            <Box className={ui.layoutContent}>
                <Box className={styles.headerGroup}>
                    <Typography marginBottom="32px" variant="h1" className={`${ui.title} ${styles.title}`}>
                        Sync Services
                    </Typography>
                    <Button variant="contained" className={styles.containedButton} onClick={addNewService}>
                        + Add service
                    </Button>
                </Box>
                <ServiceTable />
            </Box>
            <ArchivePopup />
            <ServicesPopup />
            <ZohoServicePopup />
            <EmailServicePopup />
            <RequestServicePopup />
            <WriteToUsPopup />
        </Box>
    );
});
