import { createContext, useContext } from 'react';
import { LicenseNumberFormValues } from '../components/Form/LicenseNumberForm/schema';
import { ProfileInfoFormValues } from '../components/Form/ProfileInfoForm/schema';
import { CreateCompanyFormValues } from '@schema/company/companySchema';
import { User } from '@services/OOApi';

type FormsType = {
    title: string;
    component: React.ReactNode;
};

type AllFormsValues = LicenseNumberFormValues & ProfileInfoFormValues & CreateCompanyFormValues;

type FormContextState = {
    forms: FormsType[];
    step: number;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    formValues: AllFormsValues;
    setFormValues: React.Dispatch<React.SetStateAction<AllFormsValues>>;
    user: User | null;
};

const FormContext = createContext({} as FormContextState);

const useFormContext = () => {
    const ctx = useContext(FormContext);
    if (ctx === undefined) {
        throw new Error(`useFormContext must be used within a FormProvider`);
    }

    return ctx;
};

export { FormContext, useFormContext };
export type { FormContextState };
