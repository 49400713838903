export enum ZohoSyncFrequencyEnum {
    Realtime,
    Hourly,
    Daily,
    Weekly,
}

export enum ZohoTypeEnum {
    ZohoCrm,
    ZohoRecruit,
}

export enum ZohoDomainEnum {
    Us,
    Au,
    Eu,
    In,
    Cn,
    Jp,
}

export enum ZohoEnvironmentEnum {
    Sandbox,
    Production,
}
