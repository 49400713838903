const getIdOfTabPanel = (index: number) => `account-settings-tabpanel-${index}`;
const getIdOfTab = (index: number) => `account-settings-tab-${index}`;

export const a11yTabPanelProps = (index: number) => {
    return {
        id: getIdOfTabPanel(index),
        'aria-labelledby': getIdOfTab(index),
    };
};

export const a11yTabProps = (index: number) => {
    return {
        id: getIdOfTab(index),
        'aria-controls': getIdOfTabPanel(index),
    };
};
