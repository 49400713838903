import { axiosInstance } from './axiosInstance';
import config from '@config/config';
import { CognitoUser } from 'amazon-cognito-identity-js';

export interface ILoginConfirmInputData {
    cognitoUser: CognitoUser;
    code: string;
}

type UserRole = 'Member' | 'Agency' | 'Admin' | 'AgileExpert';
type UserRoleToSet = Extract<UserRole, 'Agency' | 'AgileExpert'>;

export type User = {
    sub: string;
    /**
     * `roles` is retrieved in GET request. Array of roles.
     */
    roles: UserRole[] | null;
    /**
     * `role` is sent in `post` and `patch` payload. String. Multiple roles can be only manually added
     */
    role: UserRoleToSet;
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    gender: 'Male' | 'Female' | 'Other';
    initials: string | null;
    passportName: string | null;
    country: string | null;
    birthDate: string | null;
    birthPlace: string | null;
    photo: string | null;
    headline: string | null;
    phone: string | null;
    email: string | null;
    emailVerified: boolean;
    linkedIn: string | null;
    connectedAgencies: number;
    isOnboarded: boolean;
    updatedAt: string;
    createdAt: string;
};

class OOApi {
    async getAccount() {
        return await axiosInstance.get(`${config.OO_API}/Account/`);
    }

    async updateAccount(data: Partial<User>) {
        return await axiosInstance.post(`${config.OO_API}/Account/`, {
            ...data,
        });
    }

    async updateAvatar(formData: FormData) {
        return await axiosInstance.put(`${config.OO_API}/Account/photo`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    async sendFeedback(formData: FormData) {
        return 'Fake data';
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new OOApi();
