import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Typography } from '@mui/material';
import { useDataStore } from '@hooks/useDataStore';
import { Box } from '@mui/system';
import CompanyCard from '@components/CompanyCard';
import { Spinner } from '@components/Spinner/Spinner';
import DeleteCompanyPopup from '@components/CompanyCard/DeleteCompanyPopup';
import ManageCompanyForm from '@components/Forms/Company/ManageCompanyForm';
import EditCompanyPopup from '@components/CompanyCard/EditCompanyPopup';

const MyCompanies = observer(() => {
    const { companies } = useDataStore();

    useEffect(() => {
        if (!companies.companies) {
            companies.getMyCompanies();
        }
    }, []); // eslint-disable-line

    return (
        <>
            <Box
                sx={{
                    background: '#ffffff',
                    padding: '20px',
                    borderRadius: '10px',
                    maxWidth: '760px',
                }}
            >
                <Spinner isLoading={companies.isLoadingCompanies}>
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mb: '24px',
                            }}
                        >
                            <Typography variant="h5">Select company</Typography>
                        </Box>

                        <Box sx={{ mb: '24px' }}>
                            {companies.hasCompanies ? (
                                companies.companies.map(({ id, name, slogan, logo }, index) => {
                                    return (
                                        <CompanyCard
                                            key={id}
                                            index={index}
                                            name={name}
                                            logo={logo}
                                            id={id}
                                            slogan={slogan}
                                        />
                                    );
                                })
                            ) : (
                                <Typography>You don't have any companies created yet. Let's create one!</Typography>
                            )}
                        </Box>
                    </>
                </Spinner>
            </Box>

            <Box
                sx={{
                    background: '#ffffff',
                    padding: '20px',
                    borderRadius: '10px',
                    maxWidth: '760px',
                    mt: '20px',
                }}
            >
                <Box mb="24px">
                    <Typography variant="h5">Add new company</Typography>
                </Box>

                <ManageCompanyForm actionType="create" />

                <DeleteCompanyPopup />
                <EditCompanyPopup />
            </Box>
        </>
    );
});

export default MyCompanies;
