import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Popup } from '@components/Popup/Popup';
import { useDataStore } from '@hooks/useDataStore';
import ManageCompanyForm from '@components/Forms/Company/ManageCompanyForm';

const EditCompanyPopup = observer(() => {
    const { companies } = useDataStore();

    const handleClose = useCallback(() => {
        companies.setIsEditCompanyPopupShown(false);
    }, []);

    return (
        <Popup state={companies.isEditCompanyPopupShown} onClose={handleClose} title="Edit company">
            <ManageCompanyForm actionType="update" onSuccess={handleClose} />
        </Popup>
    );
});

export default EditCompanyPopup;
