import { makeAutoObservable } from 'mobx';
import OOApi, { User } from '@services/OOApi';

interface IUser {
    id: string;
    photo: string | null;
    firstName: string | null;
    lastName: string | null;
    gender: string | null;
    phone: string;
    phoneVerified: boolean;
    email: string | null;
    emailVerified: boolean;
    isOnboarded: boolean;
}

class UserStore {
    user: IUser = null!;
    uploadedAvatar: Blob | string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    setUploadedAvatar(avatar: Blob | null) {
        if (avatar) {
            this.uploadedAvatar = avatar;
        }
    }

    async getAccount() {
        try {
            const response = await OOApi.getAccount();
            this.user = response.data;
            this.uploadedAvatar = this.user.photo;

            return;
        } catch (error: any) {
            console.error('Failed to get account', error);
            throw new Error(error);
        }
    }

    async createAccount(data: Partial<User>) {
        try {
            const response = await OOApi.updateAccount(data);

            this.user = response.data;

            return;
        } catch (error: any) {
            console.error('Failed to update account', error);
            throw new Error(error);
        }
    }

    async updateAccount(data: Partial<User>) {
        try {
            await OOApi.updateAccount(data);
            return;
        } catch (error: any) {
            console.error('Failed to update account', error);
            throw new Error(error);
        }
    }

    async updateAvatar(formData: FormData) {
        try {
            await OOApi.updateAvatar(formData);
            const avatar = formData.get('Avatar') as Blob;
            this.setUploadedAvatar(avatar);
        } catch (error: any) {
            console.error('Failed to update avatar', error);
            throw new Error(error);
        }
    }
}

export default UserStore;
