import { useCallback, useEffect, useState } from 'react';
import { Grid, Typography, LinearProgress } from '@mui/material';
import { LoginHero } from './components/LoginHero/LoginHero';
import { LoginForm } from './components/LoginForm/LoginForm';
import { Verification } from './components/Verification/Verification';
import styles from './Login.module.scss';
import { CognitoUser } from 'amazon-cognito-identity-js';

const STEPS = {
    FIRST_STEP: 'FIRST_STEP',
    SECOND_STEP: 'SECOND_STEP',
} as const;

const PROGRESS: Record<string, number> = {
    FIRST_STEP: 33,
    SECOND_STEP: 66,
} as const;

export const Login = () => {
    const [currentYear, setCurrentYear] = useState<number | null>(null);
    const [activeStep, setActiveStep] = useState<string>(STEPS.FIRST_STEP);
    const [cognitoUser, setCognitoUser] = useState<CognitoUser>();

    const comeToVerification = useCallback(() => {
        setActiveStep(STEPS.SECOND_STEP);
    }, []);

    const onClickBackButton = useCallback(() => {
        setActiveStep(STEPS.FIRST_STEP);
    }, []);

    useEffect(() => {
        setCurrentYear(new Date().getFullYear());
    }, []);

    return (
        <Grid container>
            <Grid item xs={4}>
                <LoginHero />
            </Grid>
            <Grid item xs={8} className={styles.mainBox}>
                <LinearProgress
                    variant="determinate"
                    value={PROGRESS[activeStep]}
                    className={styles.progressBar}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transitionDuration: '200ms',
                        },
                    }}
                />

                {activeStep === STEPS.FIRST_STEP && (
                    <LoginForm comeToVerification={comeToVerification} setCognitoUser={setCognitoUser} />
                )}
                {activeStep === STEPS.SECOND_STEP && (
                    <Verification comeBackCallback={onClickBackButton} cognitoUser={cognitoUser} />
                )}

                <Typography variant="caption" className={styles.footerLaw}>
                    © {currentYear} OnlyOnce
                </Typography>
            </Grid>
        </Grid>
    );
};
