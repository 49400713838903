import * as yup from 'yup';

const simpleCreateCompanySchema = yup
    .object()
    .shape({
        logo: yup.mixed<File>().test('required', 'Company logo is required.', (value) => {
            const hasPicture = !!value?.name;
            return hasPicture;
        }),
        companyName: yup
            .string()
            .min(2, 'Company name should be at least 2 characters long.')
            .max(50, 'Company name should not be longer than 50 characters.')
            .required('Company name is required.'),
        headline: yup
            .string()
            .min(2, 'Headline should be at least 2 characters long.')
            .max(50, 'Headline should not be longer than 50 characters.')
            .required('Headline is required.'),
    })
    .defined();

export type SimpleCreaterCompanyFormValues = yup.InferType<typeof simpleCreateCompanySchema>;

export default simpleCreateCompanySchema;
