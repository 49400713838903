import { TableCell, TableHead, TableRow } from '@mui/material';
import ui from '@theme/common/ui.module.scss';

export const LogTableHead = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell sx={{ width: '13.52%' }} className={ui.tableCellHead}>
                    Sync ID
                </TableCell>
                <TableCell sx={{ width: '21.07%' }} className={ui.tableCellHead}>
                    Application
                </TableCell>
                <TableCell sx={{ width: '9.24%' }} className={ui.tableCellHead}>
                    Date&Time
                </TableCell>
                <TableCell sx={{ width: '9.24%' }} className={ui.tableCellHead}>
                    Requestor
                </TableCell>
                <TableCell sx={{ width: '9.24%' }} className={ui.tableCellHead}>
                    Owner
                </TableCell>
                <TableCell sx={{ width: '9.24%' }} className={ui.tableCellHead}>
                    Operation
                </TableCell>
                <TableCell sx={{ width: '9.24%' }} className={ui.tableCellHead}>
                    Sync Status
                </TableCell>
                <TableCell sx={{ width: '9.24%' }} className={ui.tableCellHead}>
                    Finish Status
                </TableCell>
                <TableCell sx={{ width: '9.64%' }} className={ui.tableCellHead}>
                    Category
                </TableCell>
            </TableRow>
        </TableHead>
    );
};
