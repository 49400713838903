import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './StatusLine.module.scss';

interface IStatusLineProps {
    status: string;
    text: string;
    className?: string;
}

export const StatusLine: FC<IStatusLineProps> = ({ status, text, className }) => {
    const statusColors: Record<string, string> = {
        'd77baf99-1c96-42a1-a3b5-906499dbd7bd': '#75DE50', // Synchronization
        'eab3da62-c579-4a14-9f72-ab82d0f53a73': '#75DE50', // Active
        '4ced1dcf-5794-4be2-96b0-ea6fac001be4': '#E12800', // Error
        '1faa80c0-4172-4331-989f-8c1d09fde561': '#454F59', // Archived
        '3e08aa3b-fa4d-44b1-931d-337388a715d6': '#00A0E1', // Import
        '427fa36b-d3f4-49fe-b9b8-ac33fff4b5cf': '#F5B819', // On Hold
    };

    return (
        <Box className={`${styles.statusLine} ${className}`}>
            <Box
                component="span"
                className={styles.statusLineIndicator}
                sx={{
                    backgroundColor: statusColors[status],
                }}
            ></Box>
            <Typography variant="caption" className={styles.statusLineText}>
                {text}
            </Typography>
        </Box>
    );
};
