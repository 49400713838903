import { makeAutoObservable } from 'mobx';
import OOConnect from '@services/OOConnect';
import { ZohoSyncFrequencyEnum } from '../types/ZohoServiceEnums';

interface IZohoSkopesConfiguration {
    id: string;
    name: string;
    description: string;
    isAvailable: boolean;
}

interface ISyncServiceResponse {
    id: string;
    company: {
        id: string;
        userId: string;
        name: string;
        slogan: string;
        website: string;
        logo: string;
        chamberOfCommerceNr: string;
        legalType: string;
        receptionPhone: string;
        employees: number;
        connections: number;
        createdAt: Date;
    };
    userId: string;
    type: 'ZohoCrm' | 'ZohoRecruit' | 'Email' | 'Csv';
    syncFrequency: ZohoSyncFrequencyEnum;
    status: 'Active' | 'Stopped';
    updatedAt: Date;
    createdAt: Date;
}

class SyncServicesStore {
    isArchivePopupShown = false;
    syncServicesMainData: ISyncServiceResponse[] = null!;
    zohoScopesConfiguration: IZohoSkopesConfiguration[] = null!;

    constructor() {
        makeAutoObservable(this);
    }

    toggleArchivePopup(state: boolean) {
        this.isArchivePopupShown = state;
    }

    async getSyncServices() {
        try {
            const response = await OOConnect.getSyncServices();

            this.syncServicesMainData = response.data.items;
        } catch (error: any) {
            console.error('Failed to get sync services', error);
            throw new Error(error);
        }
    }

    getZohoEnvironmentsConfiguration() {
        return OOConnect.getZohoEnvironmentsConfiguration();
    }
}

export default SyncServicesStore;
