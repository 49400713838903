import { observer } from 'mobx-react-lite';
import { Typography, Grid, Box } from '@mui/material';
import { Popup } from '@components/Popup/Popup';
import { StatusLine } from '@components/StatusLine/StatusLine';
import { SkeletonCustom } from '@components/SkeletonCustom/SkeletonCustom';
import { useDataStore } from '@hooks/useDataStore';
import zohoLogotypeImage from '@assets/images/Zoho.png';
import ui from '@theme/common/ui.module.scss';
import styles from './LogInfoPopup.module.scss';

export const LogInfoPopup = observer(() => {
    const { syncLogs } = useDataStore();
    const isLoading = syncLogs.isDetailLoading;

    const closeHandler = () => {
        syncLogs.togglePopup(false);
    };

    return (
        <Popup
            title="Log info"
            state={syncLogs.isPopupOpened}
            onClose={closeHandler}
            position="right"
            hideBackdrop={true}
            disableScrollLock={true}
        >
            <Grid container columnSpacing={10} className={styles.boxInfo}>
                <Grid item xs={6}>
                    <Typography variant="h5" className={styles.boxInfoTitle}>
                        Sync ID
                    </Typography>
                    <SkeletonCustom isLoading={isLoading} width="80px" height="32px">
                        <Typography variant="caption" className={styles.boxInfoId}>
                            SN0012
                        </Typography>
                    </SkeletonCustom>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5" className={styles.boxInfoIdTitle}>
                        Sync Status
                    </Typography>
                    <SkeletonCustom isLoading={isLoading} width="140px" height="19px">
                        <StatusLine status="synced" text="Synced" />
                    </SkeletonCustom>
                </Grid>
            </Grid>
            <SkeletonCustom isLoading={isLoading} width="100%" height="147px" marginBottom="30px" borderRadius="16px">
                <Box className={styles.boxList}>
                    <Typography variant="h5" className={styles.boxListTitle}>
                        Changes
                    </Typography>
                    <Box className={styles.boxListRow}>
                        <Typography variant="caption" className={styles.boxListSubtitle}>
                            Field
                        </Typography>
                        <Typography variant="caption" className={styles.boxListValue}>
                            First Name
                        </Typography>
                    </Box>
                    <Box className={styles.boxListRow}>
                        <Typography variant="caption" className={styles.boxListSubtitle}>
                            Old Value
                        </Typography>
                        <Typography variant="caption" className={styles.boxListValue}>
                            Dayan Fayfar{' '}
                        </Typography>
                    </Box>
                    <Box className={styles.boxListRow}>
                        <Typography variant="caption" className={styles.boxListSubtitle}>
                            New Value
                        </Typography>
                        <Typography variant="caption" className={styles.boxListValue}>
                            Diana Fayfar
                        </Typography>
                    </Box>
                </Box>
            </SkeletonCustom>
            <Grid container columnSpacing={10} className={styles.boxInfo}>
                <Grid item xs={6}>
                    <Typography variant="h5" className={styles.boxInfoTitle}>
                        Service
                    </Typography>
                    <SkeletonCustom isLoading={isLoading} width="140px" height="48px">
                        <Typography variant="caption" className={styles.boxInfoValue}>
                            <Box className={ui.cardAppBox}>
                                <Box component="figure" className={ui.cardAppBoxFigure}>
                                    <img src={zohoLogotypeImage} alt="" className={ui.cardAppBoxImage} />
                                </Box>
                                <Box component="span" className={ui.cardAppBoxText}>
                                    Zoho
                                </Box>
                            </Box>
                        </Typography>
                    </SkeletonCustom>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5" className={styles.boxInfoTitle}>
                        Date&Time
                    </Typography>
                    <SkeletonCustom isLoading={isLoading} width="140px" height="18px">
                        <Typography variant="caption" className={styles.boxInfoValue}>
                            01/10/2023 10:25PM
                        </Typography>
                    </SkeletonCustom>
                </Grid>
            </Grid>
            <Grid container columnSpacing={10} className={styles.boxInfo}>
                <Grid item xs={6}>
                    <Typography variant="h5" className={styles.boxInfoTitle}>
                        Requestor
                    </Typography>
                    <SkeletonCustom isLoading={isLoading} width="140px" height="18px">
                        <Typography variant="caption" className={styles.boxInfoValue}>
                            CocaCola
                        </Typography>
                    </SkeletonCustom>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5" className={styles.boxInfoTitle}>
                        Owner
                    </Typography>
                    <SkeletonCustom isLoading={isLoading} width="120px" height="18px">
                        <Typography variant="caption" className={styles.boxInfoValue}>
                            Dayan Fayfar
                        </Typography>
                    </SkeletonCustom>
                </Grid>
            </Grid>
            <Grid container columnSpacing={10} className={styles.boxInfo}>
                <Grid item xs={6}>
                    <Typography variant="h5" className={styles.boxInfoTitle}>
                        Operation
                    </Typography>
                    <SkeletonCustom isLoading={isLoading} width="80px" height="18px">
                        <Typography variant="caption" className={styles.boxInfoValue}>
                            Shared
                        </Typography>
                    </SkeletonCustom>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5" className={styles.boxInfoTitle}>
                        Category
                    </Typography>
                    <SkeletonCustom isLoading={isLoading} width="140px" height="18px">
                        <Typography variant="caption" className={styles.boxInfoValue}>
                            Category
                        </Typography>
                    </SkeletonCustom>
                </Grid>
            </Grid>
            <Grid container columnSpacing={10} marginBottom="80px">
                <Grid item xs={6}>
                    <Typography variant="h5" className={styles.boxInfoTitle}>
                        Owner ID
                    </Typography>
                    <SkeletonCustom isLoading={isLoading} width="120px" height="18px">
                        <Typography variant="caption" className={styles.boxInfoValue}>
                            ACTOR0934
                        </Typography>
                    </SkeletonCustom>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5" className={styles.boxInfoTitle}>
                        Owner Phone Number
                    </Typography>
                    <SkeletonCustom isLoading={isLoading} width="100px" height="18px">
                        <Typography variant="caption" className={styles.boxInfoValue}>
                            +3809483572
                        </Typography>
                    </SkeletonCustom>
                </Grid>
            </Grid>
        </Popup>
    );
});
